import { Component, Inject } from '@angular/core';
import { User } from '@models';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserService } from '@services';
import { AppState } from '../../store/store';
import { Store } from '@ngrx/store';
import * as UserActions from '../../store/actions/user.actions';

@Component({
  selector: 'add-user-dialog',
  templateUrl: './add-user-dialog.component.html',
  styleUrl: './add-user-dialog.component.scss'
})
export class AddUserDialogComponent {
  loading: boolean = false;
  user: User;
  isNew: boolean;
  phonePattern = /^\+?1?[-. ]?\(?\d{3}\)?[-. ]?\d{3}[-. ]?\d{4}$/;

  constructor(
    private store: Store<AppState>,
    public dialogRef: MatDialogRef<AddUserDialogComponent>,
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) public userData: User,
  ) {
    this.isNew = !userData?.id;
    this.user = userData || {};
  }

  onSubmit(): void {
    if (!this.user.name || !this.user.phone) {
      return;
    }

    if (!(this.phonePattern.test(this.user.phone))) {
      return;
    }

    this.user.id ? this.store.dispatch(UserActions.updateUser({user: this.user})) : this.store.dispatch(UserActions.addUser({user: this.user}));

    this.dialogRef.close(this.user);
  }
}
