<div class="content">
  <ng-container *ngIf="{
        controllerDoors: controllerDoors$ | async,
        loading: isLoading$ | async
    } as data">
    <div class="loader" *ngIf="data.loading; else controllerDoorsData">
      <mat-spinner diameter="50" color="primary"></mat-spinner>
    </div>
    <ng-template #controllerDoorsData>
      <div *ngIf="data.controllerDoors">
        <table mat-table [dataSource]="dataSource">

          <ng-container matColumnDef="position">
            <th class="w-1/12" mat-header-cell *matHeaderCellDef>Position</th>
            <ng-container *matCellDef="let controllerDoor; let $index = index">
              <td class="w-1/12 text-center" mat-cell *ngIf="!!controllerDoor else emptyRow">{{ controllerDoor?.position }}</td>
              <ng-template #emptyRow>
                <td mat-cell class="text-center" [colSpan]="displayedColumns.length">
                  <div class="flex justify-center">
                    <button color="primary" mat-button title="Add Door" (click)="showAddControllerDoorDialog($index + 1)">
                      <div class="flex justify-center items-center">
                        <fa-icon class="add-icon mr-1 text-xl" [icon]="faPlusCircle"></fa-icon>
                        <span>Add Door</span>
                      </div>
                    </button>
                  </div>
                </td>
              </ng-template>
            </ng-container>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th class="w-5/12" mat-header-cell *matHeaderCellDef>Name</th>
            <ng-container *matCellDef="let controllerDoor">
              <td class="w-5/12" mat-cell *ngIf="!!controllerDoor">{{ controllerDoor?.name }}</td>
            </ng-container>
          </ng-container>

          <ng-container matColumnDef="notes">
            <th mat-header-cell class="w-5/12 text-center" *matHeaderCellDef>Notes</th>
            <ng-container *matCellDef="let controllerDoor">
              <td mat-cell class="w-5/12 notes-column text-center" *ngIf="!!controllerDoor">
                <div
                  (click)="controllerDoor.notesCharLimit = 100 * (+!controllerDoor?.notesCharLimit)">{{ controllerDoor?.notes | charLimit: controllerDoor?.notesCharLimit }}
                  <span
                    *ngIf="controllerDoor?.notes?.length > 100 && !controllerDoor?.notesCharLimit">{{ '[-]' }}</span>
                </div>
              </td>
            </ng-container>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell class="text-center" *matHeaderCellDef></th>
            <ng-container *matCellDef="let controllerDoor">
              <td mat-cell class="gap-x-1 text-center" *ngIf="!!controllerDoor">
                <div class="text-sm">
                  <button class="icon-button" mat-icon-button title="Remove" (click)="showRemoveDialog(controllerDoor)">
                    <fa-icon [icon]="faTrash"></fa-icon>
                  </button>
                </div>
              </td>
            </ng-container>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr class="mat-row" *matNoDataRow>
            <td class="no-data-cell" [attr.colspan]="displayedColumns.length">
              No Results
            </td>
          </tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

      </div>
    </ng-template>
  </ng-container>
</div>
