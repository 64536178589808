import { createAction, props } from '@ngrx/store';
import { Card } from '@models';

export const loadCards = createAction('[Card] Load Cards');
export const loadCardsSuccess = createAction('[Card] Load Cards Success', props<{ data: Card[] }>());
export const loadCardsFailure = createAction('[Card] Load Cards Failure', props<{ error: any }>());
export const loadCard = createAction('[Card] Load Card', props<{ id: number }>());
export const loadCardSuccess = createAction('[Card] Load Card Success', props<{ card: Card }>());
export const loadCardFailure = createAction('[Card] Load Card Failure', props<{ error: any }>());
export const addCard = createAction('[Card] Add Card', props<{ card: Card }>());
export const addCardSuccess = createAction('[Card] Add Card Success', props<{ card: Card }>());
export const addCardFailure = createAction('[Card] Add Card Failure', props<{ error: any }>());
export const updateCard = createAction('[Card] Update Card', props<{ card: Card }>());
export const updateCardSuccess = createAction('[Card] Update Card Success', props<{ card: Card }>());
export const updateCardFailure = createAction('[Card] Update Card Failure', props<{ error: any }>());
export const deleteCard = createAction('[Card] Delete Card', props<{ id: number }>());
export const deleteCardSuccess = createAction('[Card] Delete Card Success', props<{ id: number }>());
export const deleteCardFailure = createAction('[Card] Delete Card Failure', props<{ error: any }>());




