import { createAction, props } from '@ngrx/store';
import { Door } from '@models';

export const loadDoors = createAction('[Door] Load Doors');
export const loadDoorsSuccess = createAction('[Door] Load Doors Success', props<{ data: Door[] }>());
export const loadDoorsFailure = createAction('[Door] Load Doors Failure', props<{ error: any }>());
export const loadDoor = createAction('[Door] Load Door', props<{ id: number }>());
export const loadDoorSuccess = createAction('[Door] Load Door Success', props<{ door: Door }>());
export const loadDoorFailure = createAction('[Door] Load Door Failure', props<{ error: any }>());

export const addDoor = createAction('[Door] Add Door', props<{ door: Partial<Door> }>());
export const addDoorSuccess = createAction('[Door] Add Door Success', props<{ door: Door }>());
export const addDoorFailure = createAction('[Door] Add Door Failure', props<{ error: any }>());
export const updateDoor = createAction('[Door] Update Door', props<{ door: Door }>());
export const updateDoorSuccess = createAction('[Door] Update Door Success', props<{ door: Door }>());
export const updateDoorFailure = createAction('[Door] Update Door Failure', props<{ error: any }>());
export const deleteDoor = createAction('[Door] Delete Door', props<{ id: number }>());
export const deleteDoorSuccess = createAction('[Door] Delete Door Success', props<{ id: number }>());
export const deleteDoorFailure = createAction('[Door] Delete Door Failure', props<{ error: any }>());
