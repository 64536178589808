import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '@services';

export const authGuard: CanActivateFn = (route, state) => {
  if (inject(AuthService).isLoggedIn()) {
    return true;
  } else {
    inject(Router).navigate(['/login'], { queryParams: { redirect: window.location.href } });
    return false;
  }
};

export const negateAuthGuard: CanActivateFn = (route, state) => {
  if (inject(AuthService).isLoggedIn()) {
    inject(Router).navigate(['/projects']);
    return false;
  } else {
    return true;
  }
}
