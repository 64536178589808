import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatAnchor, MatButton, MatIconButton } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MatIcon } from '@angular/material/icon';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { MatCard, MatCardContent, MatCardHeader, MatCardTitle } from '@angular/material/card';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { MatToolbar } from '@angular/material/toolbar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  AccountsComponent, ActivitiesComponent, CardsComponent, ControllersComponent,
  DashboardComponent, GroupComponent, GroupProjectsComponent,
  GroupsComponent, GroupUsersComponent,
  LoginComponent, ProjectComponent, ProjectControllersComponent, ProjectGroupsComponent,
  ProjectsComponent, UserCardsComponent, UserComponent, UserGroupsComponent,
  UsersComponent,
} from '@views';
import {
  MatCell, MatCellDef,
  MatColumnDef,
  MatHeaderCell, MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef, MatNoDataRow,
  MatRow, MatRowDef,
  MatTable,
} from '@angular/material/table';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import {
  AddAccountDialogComponent, AddCardDialogComponent, AddGroupDialogComponent, AddOptionsDialogComponent,
  AddProjectDialogComponent, AddUserDialogComponent, ConfirmDialogComponent, ErrorSnackbarComponent,
  LoadingDialogComponent, SearchInputComponent, SuccessSnackbarComponent,
} from '@components';
import { MatDialogActions, MatDialogClose, MatDialogContent, MatDialogTitle } from '@angular/material/dialog';
import { MatOption, MatSelect } from '@angular/material/select';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import { CharLimitPipe } from './pipe/char-limit.pipe';
import { MatTab, MatTabGroup, MatTabLink, MatTabNav, MatTabNavPanel } from '@angular/material/tabs';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { projectReducer } from './store/reducers/project.reducers';
import { ProjectEffects } from './store/effects/project.effects';
import { AccountEffects } from './store/effects/account.effects';
import { accountReducer } from './store/reducers/account.reducers';
import { userReducer } from './store/reducers/user.reducers';
import { UserEffects } from './store/effects/user.effects';
import { groupReducer } from './store/reducers/group.reducers';
import { controllerReducer } from './store/reducers/controller.reducers';
import { GroupEffects } from './store/effects/group.effects';
import { MatListOption, MatSelectionList } from '@angular/material/list';
import { ControllerEffects } from './store/effects/controller.effects';
import { CardEffects } from './store/effects/card.effects';
import { cardReducer } from './store/reducers/card.reducers';
import { utilReducer } from './store/reducers/util.reducers';
import { UtilEffects } from './store/effects/util.effects';
import { ResponseHandlingEffects } from './store/effects/response-handling.effects';
import { activityReducer } from './store/reducers/activity.reducers';
import { ActivityEffects } from './store/effects/activity.effects';
import { doorReducer } from './store/reducers/door.reducers';
import { DoorEffects } from './store/effects/door.effects';
import { DoorsComponent } from './views/doors/doors.component';
import { AddDoorDialogComponent } from './components/add-door-dialog/add-door-dialog.component';
import { ControllerComponent } from './views/controllers/controller/controller.component';
import { ControllerDoorsComponent } from './views/controllers/controller/controller-doors/controller-doors.component';
import { MatPaginator } from '@angular/material/paginator';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    AccountsComponent,
    SearchInputComponent,
    AddAccountDialogComponent,
    ProjectsComponent,
    AddProjectDialogComponent,
    CharLimitPipe,
    ConfirmDialogComponent,
    LoadingDialogComponent,
    UsersComponent,
    AddUserDialogComponent,
    GroupsComponent,
    AddGroupDialogComponent,
    GroupComponent,
    GroupProjectsComponent,
    GroupUsersComponent,
    AddOptionsDialogComponent,
    ControllersComponent,
    ProjectComponent,
    ProjectGroupsComponent,
    ProjectControllersComponent,
    CardsComponent,
    AddCardDialogComponent,
    UserComponent,
    UserGroupsComponent,
    UserCardsComponent,
    ErrorSnackbarComponent,
    SuccessSnackbarComponent,
    ActivitiesComponent,
    DoorsComponent,
    AddDoorDialogComponent,
    ControllerComponent,
    ControllerDoorsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatFormField,
    MatInput,
    MatButton,
    FormsModule,
    HttpClientModule,
    MatIcon,
    MatCard,
    MatCardHeader,
    MatCardContent,
    MatCardTitle,
    MatLabel,
    MatMenu,
    MatMenuItem,
    MatMenuTrigger,
    MatIconButton,
    MatToolbar,
    MatAnchor,
    FontAwesomeModule,
    MatTable,
    MatColumnDef,
    MatCell,
    MatHeaderCell,
    MatHeaderRow,
    MatRow,
    MatHeaderRowDef,
    MatRowDef,
    MatCellDef,
    MatHeaderCellDef,
    MatProgressSpinner,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatSelect,
    MatOption,
    MatDialogClose,
    MatSuffix,
    MatSortHeader,
    MatSort,
    MatNoDataRow,
    MatTabGroup,
    MatTab,
    MatTabNav,
    MatTabLink,
    MatTabNavPanel,
    StoreModule.forRoot({}, {}),
    EffectsModule.forRoot([]),
    StoreModule.forFeature('project', projectReducer),
    StoreModule.forFeature('account', accountReducer),
    StoreModule.forFeature('user', userReducer),
    StoreModule.forFeature('group', groupReducer),
    StoreModule.forFeature('controller', controllerReducer),
    StoreModule.forFeature('card', cardReducer),
    StoreModule.forFeature('util', utilReducer),
    StoreModule.forFeature('activity', activityReducer),
    StoreModule.forFeature('door', doorReducer),
    EffectsModule.forFeature([AccountEffects, ActivityEffects, ProjectEffects, UserEffects, GroupEffects, ControllerEffects, CardEffects, DoorEffects, UtilEffects, ResponseHandlingEffects]),
    MatSelectionList,
    MatListOption,
    MatPaginator,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    provideAnimationsAsync(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
