import { Account } from './account.model';
import { Group } from './group.model';
import { Controller } from './controller.model';

export type Project = {
  id?: number;
  createdByAccount?: Account;
  name?: string;
  notes?: string;
  timestampCreated?: string;
  notesCharLimit?: number;
  groups?: Group[];
  controllers?: Controller[];
}
