import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { Observable } from 'rxjs';
import { Card, Group, User } from '@models';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private api = () => {
    return this.appConfigService.apiBaseUrl + `/users`;
  }

  constructor(
    private http: HttpClient,
    private appConfigService: ConfigService
  ) { }

  getUsers(): Observable<User[]> {
    return this.http.get<User[]>(this.api());
  }

  getUser(id: number): Observable<User> {
    return this.http.get<User>(`${this.api()}/${id}`);
  }

  addUser(user: User): Observable<User> {
    return this.http.post<User>(this.api(), user);
  }

  updateUser(user: User): Observable<User> {
    return this.http.post<User>(`${this.api()}/${user.id}`, user);
  }

  deleteUser(id: number): Observable<User> {
    return this.http.delete<User>(`${this.api()}/${id}`);
  }

  getUserGroups(id: number): Observable<Group[]> {
    return this.http.get<Group[]>(`${this.api()}/${id}/groups`);
  }

  addUserGroups(id: number, groups: Group[]): Observable<Group[]> {
    return this.http.post<Group[]>(`${this.api()}/${id}/groups`, groups);
  }

  deleteUserGroups(id: number, groups: Group[]): Observable<Group[]> {
    return this.http.delete<Group[]>(`${this.api()}/${id}/groups`, { body: groups });
  }

  getUserCards(id: number): Observable<Card[]> {
    return this.http.get<Card[]>(`${this.api()}/${id}/cards`);
  }

  addUserCards(id: number, cards: Card[]): Observable<Card[]> {
    return this.http.post<Card[]>(`${this.api()}/${id}/cards`, cards);
  }

  deleteUserCards(id: number, cards: Card[]): Observable<Card[]> {
    return this.http.delete<Card[]>(`${this.api()}/${id}/cards`, { body: cards });
  }
}
