import { createAction, props } from '@ngrx/store';
import { Account } from '@models';

export const loadAccounts = createAction('[Account] Load Accounts');
export const loadAccountsSuccess = createAction('[Account] Load Accounts Success', props<{ data: Account[] }>());
export const loadAccountsFailure = createAction('[Account] Load Accounts Failure', props<{ error: any }>());
export const addAccount = createAction('[Account] Add Account', props<{ account: Partial<Account> }>());
export const addAccountSuccess = createAction('[Account] Add Account Success', props<{ account: Account }>());
export const addAccountFailure = createAction('[Account] Add Account Failure', props<{ error: any }>());
export const deleteAccount = createAction('[Account] Delete Account', props<{ id: number }>());
export const deleteAccountSuccess = createAction('[Account] Delete Account Success', props<{ id: number }>());
export const deleteAccountFailure = createAction('[Account] Delete Account Failure', props<{ error: any }>());
