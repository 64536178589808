import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Group } from '@models';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/store';
import * as GroupActions from '../../store/actions/group.actions';

@Component({
  selector: 'add-group-dialog',
  templateUrl: './add-group-dialog.component.html',
  styleUrl: './add-group-dialog.component.scss'
})
export class AddGroupDialogComponent {
  loading: boolean = false;
  group: Group;
  isNew: boolean;

  constructor(
    private store: Store<AppState>,
    public dialogRef: MatDialogRef<AddGroupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public groupData: Group,
  ) {
    this.isNew = !groupData?.id;
    this.group = groupData || {};
  }

  onSubmit(): void {
    if (!this.group.name) {
      return;
    }

    this.group.id ? this.store.dispatch(GroupActions.updateGroup({group: this.group})) : this.store.dispatch(GroupActions.addGroup({group: this.group}));

    this.dialogRef.close(this.group);
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }
}
