import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Card } from '@models';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../store/store';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as CardActions from '../../store/actions/card.actions';
import * as UtilSelectors from '../../store/selectors/util.selectors';
import * as UtilActions from '../../store/actions/util.actions';
import { map, Subject, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'add-card-dialog',
  templateUrl: './add-card-dialog.component.html',
  styleUrl: './add-card-dialog.component.scss'
})
export class AddCardDialogComponent implements OnInit, OnDestroy {
  loading: boolean = false;
  card: Card;
  isNew: boolean;
  cardTypeOptions: string[] = [];
  private destroy$ = new Subject<void>();

  constructor(
    private store: Store<AppState>,
    public dialogRef: MatDialogRef<AddCardDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public cardData: Card,
  ) {
    this.isNew = !cardData?.id;
    this.card = cardData || {};
  }

  ngOnInit(): void {
    this.store.pipe(
      select(UtilSelectors.selectCardTypeOptions),
      tap(cardTypeOptions => {
        // console.log(this.cardTypeOptions);
        // console.log(cardTypeOptions);
        if(!cardTypeOptions?.length) {
          this.store.dispatch(UtilActions.loadCardTypeOptions());
        }
      })  ,
      map(cardTypeOptions => {
        this.cardTypeOptions = cardTypeOptions;
        return cardTypeOptions;
      }),
      takeUntil(this.destroy$)
    ).subscribe();
  }

  onSubmit(): void {
    if (!this.card.data || !this.card.type) {
      return;
    }

    this.card.id ? this.store.dispatch(CardActions.updateCard({ card: this.card })) : this.store.dispatch(CardActions.addCard({ card: this.card }));

    this.dialogRef.close(this.card);

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
