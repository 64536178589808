import { Door } from "src/app/models";
import { createReducer, on } from '@ngrx/store';
import * as DoorActions from '../actions/door.actions';
import * as ControllerActions from '../actions/controller.actions';

export interface DoorState {
  data: Door[];
  selectedDoor: Door | null;
  loading: boolean;
  error: string;
}

export const initialDoorState: DoorState = {
  data: [],
  selectedDoor: null,
  loading: false,
  error: '',
};

const reducer = createReducer<DoorState>(
  initialDoorState,
  on(DoorActions.loadDoors, (state) => ({ ...state, loading: true })),
  on(DoorActions.loadDoorsSuccess, (state, { data }) => ({ ...state, data: data, loading: false })),
  on(DoorActions.loadDoorsFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(DoorActions.loadDoor, (state) => ({ ...state, loading: true })),
  on(DoorActions.loadDoorSuccess, (state, { door }) => ({
    ...state,
    selectedDoor: door,
    loading: false,
  })),
  on(DoorActions.loadDoorFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(DoorActions.addDoor, (state, { door }) => ({ ...state, loading: true })),
  on(DoorActions.addDoorSuccess, (state, { door }) => ({
    ...state,
    data: [...state.data, door],
    loading: false,
  })),
  on(DoorActions.addDoorFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(DoorActions.updateDoor, (state, { door }) => ({
    ...state,
    loading: true,
  })),
  on(DoorActions.updateDoorSuccess, (state, { door }) => ({
    ...state,
    data: state.data.map(d => d.id === door.id ? door : d),
    loading: false,
  })),
  on(DoorActions.updateDoorFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(DoorActions.deleteDoor, (state, { id }) => ({ ...state, loading: true })),
  on(DoorActions.deleteDoorSuccess, (state, { id }) => ({
    ...state,
    data: state.data.filter(d => d.id !== id),
    loading: false,
  })),
  on(DoorActions.deleteDoorFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(ControllerActions.addControllerDoorSuccess, (state, { data }) => ({
    ...state,
    data: state.data.map(d => data.find(item => item.id === d.id) ?? d),
  })),
  on(ControllerActions.deleteControllerDoorSuccess, (state, { doorId }) => ({
    ...state,
    data: state.data.filter(d => d.id !== doorId),
  })),
);

export function doorReducer(state = initialDoorState, action: any): DoorState {
  return reducer(state, action);
}
