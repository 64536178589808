import { createReducer, on } from "@ngrx/store";
import * as UtilActions from '../actions/util.actions';

export interface UtilState {

  cardTypeOptions: string[];
  loading: boolean;
  error: string;
}

export const initialUtilState: UtilState = {
  cardTypeOptions: [],
  loading: false,
  error: '',
};

const reducer = createReducer<UtilState>(
  initialUtilState,
  on(UtilActions.loadCardTypeOptions, (state) => ({ ...state, loading: true })),
  on(UtilActions.loadCardTypeOptionsSuccess, (state, { data }) => ({ ...state, cardTypeOptions: data, loading: false })),
  on(UtilActions.loadCardTypeOptionsFailure, (state, { error }) => ({ ...state, loading: false, error }))
);

export function utilReducer(state = initialUtilState, action: any): UtilState {
  return reducer(state, action);
}
