import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AccountService } from '@services';
import { Account } from '@models';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/store';
import * as AccountActions from '../../store/actions/account.actions';

@Component({
  selector: 'add-account-dialog',
  templateUrl: './add-account-dialog.component.html',
})
export class AddAccountDialogComponent {
  account: Account = {
    type: undefined
  }
  hidePassword: boolean = false;
  loading: boolean = false;

  constructor(
    private store: Store<AppState>,
    public dialogRef: MatDialogRef<AddAccountDialogComponent>,
    private accountService: AccountService
  ) {
  }

  onSubmit(): void {
    if(!this.account.type || !this.account.key || !this.account.identifier) {
      return;
    }
    this.store.dispatch(AccountActions.addAccount({ account: this.account }));
    this.dialogRef.close(this.account);
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  togglePasswordVisibility(): void {
    this.hidePassword = !this.hidePassword;
  }
}
