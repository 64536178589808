import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Group } from '@models';
import { map, Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as GroupSelectors from '../../../store/selectors/group.selectors';
import * as GroupActions from '../../../store/actions/group.actions';
import { AppState } from '../../../store/store';

@Component({
  selector: 'group',
  templateUrl: './group.component.html',
  styleUrl: './group.component.scss',
})
export class GroupComponent implements OnInit {
  group$?: Observable<Group | null>;
  isLoading$?: Observable<boolean>;
  group?: Group;

  constructor(
    private route: ActivatedRoute,
    private store: Store<AppState>,
  ) {
    this.group$ = this.store.pipe(select(GroupSelectors.selectSelectedGroup));
  }

  ngOnInit(): void {
    this.initSubscriptions();
  }

  initSubscriptions(): void {
    this.route.paramMap.pipe(
      map(params => params.get('id'))
    ).subscribe(id => {
      if (id) {
        this.store.dispatch(GroupActions.loadGroup({ id: +id }));
      }
    });
    this.isLoading$ = this.store.pipe(select(GroupSelectors.selectGroupIsLoading));
  }
}
