import { Group } from '@models';
import { createReducer, on } from '@ngrx/store';
import * as GroupActions from '../actions/group.actions';

export interface GroupState {
  data: Group[];
  selectedGroup: Group | null;
  loading: boolean;
  error: string;
}

export const initialGroupState: GroupState = {
  data: [],
  selectedGroup: null,
  loading: false,
  error: '',
};

const reducer = createReducer<GroupState>(
  initialGroupState,
  on(GroupActions.loadGroups, (state) => ({ ...state, loading: true })),
  on(GroupActions.loadGroupsSuccess, (state, { data }) => ({ ...state, data: data, loading: false })),
  on(GroupActions.loadGroupsFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(GroupActions.loadGroup, (state) => ({ ...state, loading: true })),
  on(GroupActions.loadGroupSuccess, (state, { group }) => ({ ...state, selectedGroup: group, loading: false })),
  on(GroupActions.loadGroupFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(GroupActions.addGroup, (state, { group }) => ({ ...state, loading: true })),
  on(GroupActions.addGroupSuccess, (state, { group }) => ({
    ...state,
    data: [...state.data, group],
    loading: false,
  })),
  on(GroupActions.addGroupFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(GroupActions.updateGroup, (state, { group }) => ({
    ...state,
    loading: true,
  })),
  on(GroupActions.updateGroupSuccess, (state, { group }) => ({
    ...state,
    groups: state.data.map(g => g.id === group.id ? group : g),
    loading: false,
  })),
  on(GroupActions.updateGroupFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(GroupActions.deleteGroup, (state, { id }) => ({ ...state, loading: true })),
  on(GroupActions.deleteGroupSuccess, (state, { id }) => ({
    ...state,
    data: state.data.filter(g => g.id !== id),
    loading: false,
  })),
  on(GroupActions.deleteGroupFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(GroupActions.loadGroupProjects, (state) => ({ ...state, loading: true })),
  on(GroupActions.loadGroupProjectsSuccess, (state, data) => {
    return {
      ...state,
      selectedGroup: { ...state.selectedGroup, projects: data.projects },
      loading: false,
    };
  }),
  on(GroupActions.loadGroupProjectsFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(GroupActions.addGroupProjects, (state) => ({ ...state, loading: true })),
  on(GroupActions.addGroupProjectsSuccess, (state, { data }) => ({
    ...state,
    selectedGroup: { ...state.selectedGroup, projects: data },
    loading: false,
  })),
  on(GroupActions.addGroupProjectsFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(GroupActions.deleteGroupProjects, (state) => ({ ...state, loading: true })),
  on(GroupActions.deleteGroupProjectsSuccess, (state, { data }) => ({
    ...state,
    selectedGroup: {
      ...state.selectedGroup,
      projects: state.selectedGroup?.projects?.filter(p => !data.some(dp => dp.id === p.id)),
    },
    loading: false,
  })),
  on(GroupActions.deleteGroupProjectsFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(GroupActions.loadGroupUsers, (state) => ({ ...state, loading: true })),
  on(GroupActions.loadGroupUsersSuccess, (state, data) => {
    return {
      ...state,
      selectedGroup: { ...state.selectedGroup, users: data.users },
      loading: false,
    };
  }),
  on(GroupActions.loadGroupUsersFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(GroupActions.addGroupUsers, (state) => ({ ...state, loading: true })),
  on(GroupActions.addGroupUsersSuccess, (state, { data }) => ({
    ...state,
    selectedGroup: { ...state.selectedGroup, users: data },
    loading: false,
  })),
  on(GroupActions.addGroupUsersFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(GroupActions.deleteGroupUsers, (state) => ({ ...state, loading: true })),
  on(GroupActions.deleteGroupUsersSuccess, (state, { data }) => ({
    ...state,
    selectedGroup: {
      ...state.selectedGroup,
      users: state.selectedGroup?.users?.filter(u => !data.some(du => du.id === u.id)),
    },
    loading: false,
  })),
  on(GroupActions.deleteGroupUsersFailure, (state, { error }) => ({ ...state, loading: false, error })),
);

export function groupReducer(state = initialGroupState, action: any): GroupState {
  return reducer(state, action);
}
