import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { Observable } from 'rxjs';
import { Door } from '@models';

@Injectable({
  providedIn: 'root'
})
export class DoorService {

  constructor(
    private http: HttpClient,
    private appConfigService: ConfigService
  ) { }

  private api = () => {
    return this.appConfigService.apiBaseUrl + `/doors`;
  };

  getDoors(): Observable<Door[]>{
    return this.http.get<Door[]>(this.api());
  }

  getDoor(id: number): Observable<Door>{
    return this.http.get<Door>(this.api() + `/${id}`);
  }

  addDoor(door: Door): Observable<Door>{
    return this.http.post<Door>(this.api(), door);
  }

  updateDoor(door: Door): Observable<Door>{
    return this.http.post<Door>(this.api() + `/${door.id}`, door);
  }

  deleteDoor(id: number): Observable<Door>{
    return this.http.delete<Door>(this.api() + `/${id}`);
  }
}
