export type Account = {
  id?: number;
  username?: string;
  name?: string;
  identifier?: string;
  key?: string;
  timestampCreated?: string;
  type?: keyof typeof AccountType | undefined;
  displayName?: string;
}

export enum AccountType {
  SYSTEM = "Service",
  PERSON = "User"
}
