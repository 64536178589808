import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { Observable } from 'rxjs';
import { Controller, Group, Project } from '@models';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  private api = () => {
    return this.appConfigService.apiBaseUrl + `/projects`;
  };

  constructor(
    private http: HttpClient,
    private appConfigService: ConfigService
  ) {}

  getProjects(): Observable<Project[]> {
    return this.http.get<Project[]>(this.api());
  }

  getProject(id: number): Observable<Project> {
    return this.http.get<Project>(this.api() + `/${id}`);
  }

  addProject(project: Project): Observable<Project> {
    return this.http.post<Project>(this.api(), project);
  }

  updateProject(project: Project): Observable<Project> {
    return this.http.post<Project>(this.api() + `/${project.id}`, project);
  }

  deleteProject(id: number): Observable<Project> {
    return this.http.delete<Project>(this.api() + `/${id}`);
  }

  getProjectGroups(id: number): Observable<Group[]> {
    return this.http.get<Group[]>(this.api() + `/${id}/groups`);
  }

  addProjectGroups(id: number, groups: Group[]): Observable<Group[]> {
    return this.http.post<Group[]>(this.api() + `/${id}/groups`, groups);
  }

  deleteProjectGroups(id: number, groups: Group[]): Observable<Group[]> {
    return this.http.delete<Group[]>(this.api() + `/${id}/groups`, { body: groups });
  }

  getProjectControllers(id: number): Observable<Controller[]> {
    return this.http.get<Controller[]>(this.api() + `/${id}/controllers`);
  }

  addProjectControllers(id: number, controllers: Controller[]): Observable<Controller[]> {
    return this.http.post<Controller[]>(this.api() + `/${id}/controllers`, controllers);
  }

  deleteProjectControllers(id: number, controllers: Controller[]): Observable<Controller[]> {
    return this.http.delete<Controller[]>(this.api() + `/${id}/controllers`, { body: controllers });
  }
}
