import { Component, OnInit, ViewChild } from '@angular/core';
import { Group } from '@models';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { SharedService } from '@services';
import { MatDialog } from '@angular/material/dialog';
import { faEdit, faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import {
  AddGroupDialogComponent,
  ConfirmDialogComponent,
  ConfirmDialogType
} from '@components';
import { map, Observable, tap } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../store/store';
import * as GroupSelectors from '../../store/selectors/group.selectors';
import * as GroupActions from '../../store/actions/group.actions';

@Component({
  selector: 'groups',
  templateUrl: './groups.component.html',
  styleUrl: './groups.component.scss',
})
export class GroupsComponent implements OnInit {
  groups$?: Observable<Group[]>
  isLoading$?: Observable<boolean>;
  groups: Group[] = [];
  displayedColumns: string[] = ['name', 'createdByAccount.username', 'notes', 'timestampCreated', 'actions'];
  loading?: boolean;
  searchValue?: string;
  dataSource = new MatTableDataSource<Group>();

  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
    this.dataSource.sortingDataAccessor = this.sharedService.sortingDataAccessorForNestedProperties;
  };

  constructor(
    private store: Store<AppState>,
    private sharedService: SharedService,
    public dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    this.initSubscriptions();
  }

  initSubscriptions(): void {
    this.groups$ = this.store.pipe(
      select(GroupSelectors.selectGroups),
      tap(groups => {
        if (!groups || groups.length === 0) {
          this.store.dispatch(GroupActions.loadGroups());
        }
      }),
      map(groups => {
        this.groups = groups.map(p => ({ ...p, notesCharLimit: 100 }));
        this.dataSource.data = this.groups;
        return groups;
      }),
    );
    this.isLoading$ = this.store.pipe(select(GroupSelectors.selectGroupIsLoading));
  }

  filterGroups(val: string | undefined): void {
    this.searchValue = val;
    if (!val) {
      this.dataSource.data = this.groups;
      return;
    }
    this.dataSource.data = this.groups.filter(g => ['name', 'notes', 'timestampCreated']
      .some(k => g[k as keyof Group]?.toString().toLowerCase().includes(val.toLowerCase())
        || g.createdByAccount?.username?.toLowerCase().includes(val.toLowerCase())));
  }

  sortFunction(a: Group, b: Group): number {
    return a.name!.toLowerCase().localeCompare(b.name!.toLowerCase());
  }

  showManageGroupDialog(group?: Group): void {
    this.dialog.open(AddGroupDialogComponent, {
      width: '500px',
      data: { ...group },
    });

  }

  showDeleteDialog(group: Group): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        type: ConfirmDialogType.DELETE,
        title: 'Delete Group',
        message: `Are you sure you want to delete group \'${group.name}\'?`,
        okText: 'Delete',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.store.dispatch(GroupActions.deleteGroup( { id: group.id! }));
      }
    });
  }

  protected readonly faEdit = faEdit;
  protected readonly faTrash = faTrash;
  protected readonly faPlusCircle = faPlusCircle;
}
