import { User } from "./user.model";
import { Controller } from './controller.model';

export type Activity = {
  id?: number;
  timestamp?: string;
  event?: string;
  user?: User;
  site?: string;
  controller?: Controller;
};
