import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { Observable } from 'rxjs';
import { Card } from '@models';

@Injectable({
  providedIn: 'root'
})
export class CardService {

  constructor(
    private http: HttpClient,
    private appConfigService: ConfigService
  ) { }

  private api = () => {
    return this.appConfigService.apiBaseUrl + `/cards`;
  };

  getCards(): Observable<Card[]>{
    return this.http.get<Card[]>(this.api());
  }

  getCard(id: number): Observable<Card>{
    return this.http.get<Card>(this.api() + `/${id}`);
  }

  addCard(card: Card): Observable<Card>{
    return this.http.post<Card>(this.api(), card);
  }

  updateCard(card: Card): Observable<Card>{
    return this.http.post<Card>(this.api() + `/${card.id}`, card);
  }

  deleteCard(id: number): Observable<Card>{
    return this.http.delete<Card>(this.api() + `/${id}`);
  }
}
