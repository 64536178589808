<div class="page controllers">
  <ng-container *ngIf="{
        controllers: controllers$ | async,
        loading: isLoading$ | async
    } as data">
    <div class="container">
      <div class="header">
        <div class="title">Controllers</div>
        <div class="page-actions">

        </div>
      </div>
      <div class="content">
        <div class="loader" *ngIf="data.loading; else controllersData">
          <mat-spinner diameter="50" color="primary"></mat-spinner>
        </div>
        <ng-template #controllersData>
          <div *ngIf="controllers">
            <search-input (searchInputChange)="filterControllers($event)"></search-input>
            <table mat-table [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="asc">

              <!-- Name Column -->
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">Name</th>
                <td mat-cell *matCellDef="let controller">
                  <a [routerLink]="['./', controller.id]">{{ controller.name }}</a>
                </td>
              </ng-container>

              <ng-container matColumnDef="serialNumber">
                <th mat-header-cell *matHeaderCellDef>Serial Number</th>
                <td mat-cell *matCellDef="let controller">{{ controller.serialNumber }}</td>
              </ng-container>

              <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef>Type</th>
                <td mat-cell *matCellDef="let controller">{{ controller.type }}</td>
              </ng-container>

              <ng-container matColumnDef="createdByAccount.username">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by creator">Created By
                </th>
                <td mat-cell *matCellDef="let controller">{{ controller.createdByAccount?.username }}</td>
              </ng-container>

              <!-- Type Column -->
              <ng-container matColumnDef="notes">
                <th mat-header-cell class="text-center" *matHeaderCellDef>Notes</th>
                <td mat-cell class="notes-column text-center" *matCellDef="let controller">
                  <div
                    (click)="controller.notesCharLimit = 100 * (+!controller.notesCharLimit)">{{ controller.notes | charLimit: controller.notesCharLimit }}
                    <span *ngIf="controller.notes?.length > 100 && !controller.notesCharLimit">{{ '[-]' }}</span>
                  </div>
                </td>
              </ng-container>

              <!-- Timestamp Created Column -->
              <ng-container matColumnDef="timestampCreated">
                <th mat-header-cell class="text-center" *matHeaderCellDef mat-sort-header
                    sortActionDescription="Sort by datetime created">Datetime Created
                </th>
                <td mat-cell class="text-center"
                    *matCellDef="let controller">{{ controller.timestampCreated | date: 'MMM dd, YYYY HH:mm' }}
                </td>
              </ng-container>

              <ng-container matColumnDef="actions">
                <th mat-header-cell class="text-center" *matHeaderCellDef></th>
                <td mat-cell class="flex gap-x-1 text-center" *matCellDef="let controller">

                </td>
              </ng-container>

              <!-- Column definitions -->
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr class="mat-row" *matNoDataRow>
                <td class="no-data-cell" [attr.colspan]="displayedColumns.length">
                  No Results
                </td>
              </tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

          </div>
        </ng-template>
      </div>
    </div>
  </ng-container>
</div>
