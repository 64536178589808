import { Account } from './account.model';
import { Door } from './door.model';

export type Controller = {
  id?: number;
  name?: string;
  serialNumber?: string;
  type?: string;
  notes?: string;
  createdByAccount?: Account;
  timestampCreated?: string;
  notesCharLimit?: number;
  doors?: Door[];
}
