// auth.service.ts

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigService } from '@services';

export interface Credentials {
  username: string;
  password: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private token?: string;
  private api = () => {
    return this.appConfigService.apiBaseUrl + `/accounts`;
  };

  private baseUrl = () => {
    return this.appConfigService.baseUrl;
  }

  constructor(
    private http: HttpClient,
    private appConfigService: ConfigService
  ) {}

  login(data: Credentials): Observable<any> {
    return this.http.post<Credentials>(`${this.api()}/login`, data);
  }

  setToken(token: string): void {
    this.token = token;
    localStorage.setItem('token', token); // Store the token in localStorage
  }

  getToken(): string | null {
    return this.token || localStorage.getItem('token');
  }

  logout(): void {
    this.token = undefined;
    localStorage.removeItem('token'); // Remove the token from localStorage
  }

  isLoggedIn(): boolean {
    return !!this.getToken();
  }

  clearUser(redirectUrl: string = ''): void {
    this.logout();
    console.log(this.baseUrl());
    window.location.assign(`${this.baseUrl()}/login?redirect=${encodeURIComponent(redirectUrl)}`);
  }
}
