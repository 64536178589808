import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { map, Observable, Subject, takeUntil, tap } from 'rxjs';
import { Group, User } from '@models';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../store/store';
import { SharedService } from '@services';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import * as UserSelectors from '../../../../store/selectors/user.selectors';
import * as UserActions from '../../../../store/actions/user.actions';
import * as GroupSelectors from '../../../../store/selectors/group.selectors';
import * as GroupActions from '../../../../store/actions/group.actions';
import { AddOptionsDialogComponent, ConfirmDialogComponent, ConfirmDialogType } from '@components';
import { faCircleMinus, faPlusCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'user-groups',
  templateUrl: './user-groups.component.html',
  styleUrl: './user-groups.component.scss'
})
export class UserGroupsComponent implements OnInit, OnDestroy {
  userGroups$?: Observable<Group[] | undefined>
  isLoading$?: Observable<boolean>
  userGroups: Group[] = [];
  groups: Group[] = [];
  userId?: number;
  user: User = {} as User;
  displayedColumns: string[] = ['name', 'createdByAccount.username', 'notes', 'timestampCreated', 'actions'];
  dataSource = new MatTableDataSource<Group>();
  private unsubscribe$ = new Subject<void>();

  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
    this.dataSource.sortingDataAccessor = this.sharedService.sortingDataAccessorForNestedProperties;
  };

  constructor(
    private store: Store<AppState>,
    private sharedService: SharedService,
    public dialog: MatDialog,
    public route: ActivatedRoute,
  ) {
    this.store.pipe(select(UserSelectors.selectSelectedUser)).subscribe(user => this.user = user!);
  }

  ngOnInit(): void {
    this.initSubscriptions();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  initSubscriptions(): void {
    this.route.parent?.paramMap.pipe(
      map(params => params.get('id')),
      tap(id => {
        if (id) {
          this.userId = +id;
          this.userGroups$ = this.store.pipe(
            select(UserSelectors.selectSelectedUserGroups),
            tap(usersGroups => {
              if (!usersGroups) {
                this.store.dispatch(UserActions.loadUserGroups({ userId: +id }));
              }
            }),
            map(userGroups => {
              this.userGroups = (userGroups || []).map(pg => ({ ...pg, notesCharLimit: 100 }));
              this.dataSource.data = this.userGroups;
              return userGroups;
            })
          );
        }
      }),
      takeUntil(this.unsubscribe$),
    ).subscribe();

    this.store.pipe(
      select(GroupSelectors.selectGroups),
      tap(groups => {
        if (!groups || groups.length === 0) {
          this.store.dispatch(GroupActions.loadGroups());
        }
      }),
      takeUntil(this.unsubscribe$),
    ).subscribe(groups => {
      this.groups = groups;
    });

    this.isLoading$ = this.store.pipe(
      select(UserSelectors.selectUserIsLoading),
      takeUntil(this.unsubscribe$),
    );
  }

  showAddUserGroupsDialog(): void {
    const dialogRef = this.dialog.open(AddOptionsDialogComponent, {
      width: '400px',
      data: {
        options: this.groups.filter(g => !this.userGroups.some(ug => ug.id === g.id)),
        displayProperty: 'name',
        title: 'Select Groups'
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.store.dispatch(UserActions.addUserGroups({ userId: this.userId!, groups: result }));
      }
    });
  }

  showRemoveDialog(group: Group): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        type: ConfirmDialogType.DELETE,
        title: 'Remove Group',
        message: `Are you sure you want to remove group \'${group.name}\' from \'${this.user.name}\'?`,
        okText: 'Remove',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.store.dispatch(UserActions.deleteUserGroups({ userId: this.user.id!, groups: [group] }));
      }
    });
  }

  protected readonly faPlusCircle = faPlusCircle;
  protected readonly faCircleMinus = faCircleMinus;
}
