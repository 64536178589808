import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { faCircleMinus, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { Group, Project } from '@models';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { SharedService } from '@services';
import { MatDialog } from '@angular/material/dialog';
import { AddOptionsDialogComponent, ConfirmDialogComponent, ConfirmDialogType } from '@components';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../store/store';
import * as GroupSelectors from '../../../../store/selectors/group.selectors';
import { map, Observable, Subject, takeUntil, tap } from 'rxjs';
import * as GroupActions from '../../../../store/actions/group.actions';
import * as ProjectActions from '../../../../store/actions/project.actions';
import * as ProjectSelectors from '../../../../store/selectors/project.selectors';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'group-projects',
  templateUrl: './group-projects.component.html',
  styleUrl: './group-projects.component.scss',
})
export class GroupProjectsComponent implements OnInit, OnDestroy {
  groupProjects$?: Observable<Project[] | undefined>;
  isLoading$?: Observable<boolean>;
  groupProjects: Project[] = [];
  projects: Project[] = [];
  groupId?: number;
  group: Group = {} as Group;
  displayedColumns: string[] = ['name', 'createdByAccount.username', 'notes', 'timestampCreated', 'actions'];
  dataSource = new MatTableDataSource<Project>();
  private unsubscribe$ = new Subject<void>();

  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
    this.dataSource.sortingDataAccessor = this.sharedService.sortingDataAccessorForNestedProperties;
  };

  constructor(
    private store: Store<AppState>,
    private sharedService: SharedService,
    public dialog: MatDialog,
    public route: ActivatedRoute,
  ) {
    this.store.pipe(select(GroupSelectors.selectSelectedGroup)).subscribe(group => this.group = group!);
  }

  ngOnInit(): void {
    this.initSubscriptions();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  initSubscriptions(): void {
    this.route.parent?.paramMap.pipe(
      map(params => params.get('id')),
      tap(id => {
        if (id) {
          this.groupId = +id;
          this.groupProjects$ = this.store.pipe(
            select(GroupSelectors.selectSelectedGroupProjects),
            tap(groupProjects => {
              if (!groupProjects) {
                this.store.dispatch(GroupActions.loadGroupProjects({ groupId: +id }));
              }
            }),
            map(groupProjects => {
              this.groupProjects = (groupProjects || []).map(gp => ({ ...gp, notesCharLimit: 100 }));
              this.dataSource.data = this.groupProjects;
              return groupProjects;
            })
          );
        }
      }),
      takeUntil(this.unsubscribe$),
    ).subscribe();

    this.store.pipe(
      select(ProjectSelectors.selectProjects),
      tap(projects => {
        if (!projects || projects.length === 0) {
          this.store.dispatch(ProjectActions.loadProjects());
        }
      }),
      takeUntil(this.unsubscribe$),
    ).subscribe(projects => {
      this.projects = projects;
    });

    this.isLoading$ = this.store.pipe(
      select(GroupSelectors.selectGroupIsLoading),
      takeUntil(this.unsubscribe$),
    );
  }

  showAddGroupProjectsDialog(): void {
    const dialogRef = this.dialog.open(AddOptionsDialogComponent, {
      width: '400px',
      data: {
        options: this.projects.filter(p => !this.groupProjects.some(gp => gp.id === p.id)),
        displayProperty: 'name',
        title: 'Select Projects'
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.store.dispatch(GroupActions.addGroupProjects({ groupId: this.groupId!, projects: result }));
      }
    });
  }

  showRemoveDialog(project: Project): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        type: ConfirmDialogType.DELETE,
        title: 'Remove Project',
        message: `Are you sure you want to remove project \'${project.name}\' from \'${this.group.name}\'?`,
        okText: 'Remove',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.store.dispatch(GroupActions.deleteGroupProjects({ groupId: this.group.id!, projects: [project] }));
      }
    });
  }

  protected readonly faPlusCircle = faPlusCircle;
  protected readonly faCircleMinus = faCircleMinus;
}
