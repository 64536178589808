import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { ThemePalette } from '@angular/material/core';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent{
  title = 'frontend';
  primaryColor: ThemePalette = 'primary';
  constructor(private router: Router) {

  }

  isLoginRoute(): boolean {
    return this.router.url.startsWith('/login');
  }

  logout() {
    localStorage.removeItem('token');
    this.router.navigate(['/login']);
  }

  protected readonly faSignOutAlt = faSignOutAlt;
}
