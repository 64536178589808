<div class="dark-theme">
  <div class="login-container">
    <mat-card class="login-card">
      <mat-card-header class="card-header">
        <mat-card-title class="card-title">Access Control</mat-card-title>
      </mat-card-header>
      <i class="icon fas fa-door-open fa-10x"></i>
      <mat-card-content>
        <form class="login-form" (ngSubmit)="login()">
          <mat-form-field class="form-group">
            <input matInput placeholder="Username" id="username" name="username" [(ngModel)]="username" required>
          </mat-form-field>
          <mat-form-field class="form-group">
            <input matInput type="password" placeholder="Password" id="password" name="password" [(ngModel)]="password" required>
          </mat-form-field>
          <button class="login-button" mat-raised-button color="primary" type="submit">
            <fa-icon class="icon" [icon]="faSignIn"></fa-icon>
            Sign In
          </button>
        </form>
      </mat-card-content>
      <div *ngIf="errorMessage" class="error-message">{{ errorMessage }}</div>
    </mat-card>
  </div>
</div>
