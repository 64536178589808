import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard, negateAuthGuard } from './guards/auth.guard';
import {
  AccountsComponent,
  ActivitiesComponent,
  CardsComponent,
  ControllerComponent,
  ControllerDoorsComponent,
  ControllersComponent,
  DoorsComponent,
  GroupComponent,
  GroupProjectsComponent,
  GroupsComponent,
  GroupUsersComponent,
  LoginComponent,
  ProjectComponent,
  ProjectControllersComponent,
  ProjectGroupsComponent,
  ProjectsComponent,
  UserCardsComponent,
  UserComponent,
  UserGroupsComponent,
  UsersComponent,
} from '@views';

const routes: Routes = [
  { path: '', redirectTo: 'projects', pathMatch: 'full' },
  { path: 'accounts', component: AccountsComponent, canActivate: [authGuard] },
  { path: 'activities', component: ActivitiesComponent, canActivate: [authGuard] },
  { path: 'cards', component: CardsComponent, canActivate: [authGuard] },
  {
    path: 'controllers', canActivate: [authGuard], children:
      [
        { path: '', component: ControllersComponent },
        {
          path: ':id', component: ControllerComponent, children: [
            { path: '', redirectTo: 'doors', pathMatch: 'full' },
            { path: 'doors', component: ControllerDoorsComponent },
          ],
        },
      ],
  },
  {
    path: 'groups', canActivate: [authGuard], children:
      [
        { path: '', component: GroupsComponent },
        {
          path: ':id', component: GroupComponent, children: [
            { path: '', redirectTo: 'projects', pathMatch: 'full' },
            { path: 'projects', component: GroupProjectsComponent },
            { path: 'users', component: GroupUsersComponent },
          ],
        },
      ],
  },
  {
    path: 'projects',
    canActivate: [authGuard],
    children: [{ path: '', component: ProjectsComponent }, {
      path: ':id',
      component: ProjectComponent,
      children: [
        { path: '', redirectTo: 'groups', pathMatch: 'full' },
        { path: 'groups', component: ProjectGroupsComponent },
        { path: 'controllers', component: ProjectControllersComponent },
      ],
    }],
  },
  {
    path: 'users', canActivate: [authGuard], children: [
      { path: '', component: UsersComponent },
      {
        path: ':id', component: UserComponent, children: [
          { path: '', redirectTo: 'groups', pathMatch: 'full' },
          { path: 'groups', component: UserGroupsComponent },
          { path: 'cards', component: UserCardsComponent },
        ],
      },
    ],
  },
  { path: 'login', component: LoginComponent, canActivate: [negateAuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
