import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'search-input',
  templateUrl: './search-input.component.html',
  styleUrl: './search-input.component.scss'
})
export class SearchInputComponent implements AfterViewInit {
  @Input() initialValue: string = '';
  @Output() searchInputChange: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('input') input: ElementRef | undefined;

  ngAfterViewInit(): void {
    this.input!.nativeElement.value = this.initialValue || '';
  }

  emitSearchValue(event: Event): void {
    this.searchInputChange.emit((event.target as HTMLInputElement).value);
  }

  clearInput(): void {
    this.input!.nativeElement.value = '';
    this.searchInputChange.emit('');
  }
}
