// login.component.ts

import { Component } from '@angular/core';
import { AuthService, ConfigService, Credentials } from '@services';
import { Router } from '@angular/router';
import { faSignIn } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  username: string = '';
  password: string = '';
  errorMessage: string = '';

  constructor(
    private authService: AuthService,
    private appConfigService: ConfigService,
    private router: Router
  ) {
  }

  login(): void {
    if(!this.username?.length || !this.password?.length) {
      return;
    }
    const credentials: Credentials = { username: this.username, password: this.password };
    this.authService.login(credentials).subscribe(response => {
      if (response && response.token) {
        this.authService.setToken(response.token);
        const redirectUrl = new URLSearchParams(window.location.search).get('redirect');
        if (redirectUrl?.startsWith(this.appConfigService.baseUrl)) {
          this.router.navigateByUrl(redirectUrl.substring(this.appConfigService.baseUrl.length));
        } else {
          // Redirect to the base href
          this.router.navigateByUrl('/projects');
        }
      } else {
        this.errorMessage = 'Invalid username or password';
      }
    }, res => {
      this.errorMessage = res?.error?.message || 'An error occurred while logging in. Please try again later.';
    });
  }

  protected readonly faSignIn = faSignIn;
}
