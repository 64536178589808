import { Card } from "src/app/models";
import { createReducer, on } from '@ngrx/store';
import * as CardActions from '../actions/card.actions';

export interface CardState {
  data: Card[];
  selectedCard: Card | null;
  loading: boolean;
  error: string;
}

export const initialCardState: CardState = {
  data: [],
  selectedCard: null,
  loading: false,
  error: '',
};

const reducer = createReducer<CardState>(
  initialCardState,
  on(CardActions.loadCards, (state) => ({ ...state, loading: true })),
  on(CardActions.loadCardsSuccess, (state, { data }) => ({ ...state, data: data, loading: false })),
  on(CardActions.loadCardsFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(CardActions.loadCard, (state) => ({ ...state, loading: true })),
  on(CardActions.loadCardSuccess, (state, { card }) => ({
    ...state,
    selectedCard: card,
    loading: false,
  })),
  on(CardActions.loadCardFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(CardActions.addCard, (state, { card }) => ({ ...state, loading: true })),
  on(CardActions.addCardSuccess, (state, { card }) => ({
    ...state,
    data: [...state.data, card],
    loading: false,
  })),
  on(CardActions.addCardFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(CardActions.updateCard, (state, { card }) => ({
    ...state,
    loading: true,
  })),
  on(CardActions.updateCardSuccess, (state, { card }) => ({
    ...state,
    data: state.data.map(c => c.id === card.id ? card : c),
    loading: false,
  })),
  on(CardActions.updateCardFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(CardActions.deleteCard, (state, { id }) => ({ ...state, loading: true })),
  on(CardActions.deleteCardSuccess, (state, { id }) => ({
    ...state,
    data: state.data.filter(c => c.id !== id),
    loading: false,
  })),
  on(CardActions.deleteCardFailure, (state, { error }) => ({ ...state, loading: false, error })),

);

export function cardReducer(state = initialCardState, action: any): CardState {
  return reducer(state, action);
}
