import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '@services';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor(
    private http: HttpClient,
    private appConfigService: ConfigService
  ) { }

  private api = () => {
    return this.appConfigService.apiBaseUrl + `/utils`;
  }

  getCardTypeOptions(): Observable<string[]> {
    return this.http.get<string[]>(`${this.api()}/card-type-options`);
  }

  paramsSerializer(filters: Record<string, any>): HttpParams {
    let httpParams = new HttpParams();
    for (const key in filters) {
      if (filters.hasOwnProperty(key) && filters[key] !== null && filters[key] !== undefined) {
        if (Array.isArray(filters[key])) {
          httpParams = httpParams.set(key, filters[key].join(','));
        } else {
          httpParams = httpParams.set(key, filters[key].toString());
        }
      }
    }
    return httpParams;
  }
}
