<div class="page group">
  <div class="container" *ngIf="group$ | async as group; else loading">
    <div class="header">
      <div class="title">{{ group.name }}</div>
      <div class="page-actions"></div>
    </div>
    <div class="content">

      <nav mat-tab-nav-bar [tabPanel]="tabPanel">
        <a mat-tab-link routerLink="./projects" routerLinkActive #rla="routerLinkActive" [active]="rla.isActive">Projects</a>
        <a mat-tab-link routerLink="./users" routerLinkActive #rla2="routerLinkActive"
           [active]="rla2.isActive">Users</a>
        <!-- Add more tabs as needed -->
      </nav>

      <mat-tab-nav-panel class="flex flex-1" #tabPanel>
        <router-outlet></router-outlet>
      </mat-tab-nav-panel>
    </div>
  </div>
  <ng-template #loading>
    <div class="flex flex-1 justify-center items-center">
      <mat-spinner diameter="50" color="primary"></mat-spinner>
    </div>
  </ng-template>
</div>
