import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { UtilService } from '@services';
import * as UtilActions from '../actions/util.actions';
import { catchError, map, mergeMap, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UtilEffects {
  constructor(
    private actions$: Actions,
    private utilService: UtilService
  ) {}

  loadCardTypeOptions$ = createEffect(() => this.actions$.pipe(
    ofType(UtilActions.loadCardTypeOptions),
    mergeMap(() => this.utilService.getCardTypeOptions().pipe(
      map(data => UtilActions.loadCardTypeOptionsSuccess({ data })),
      catchError(error => of(UtilActions.loadCardTypeOptionsFailure({ error })))
    ))
  ));
}
