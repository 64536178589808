<div class="flex justify-between items-baseline">
  <h2 mat-dialog-title>{{ isNew ? 'Add New' : 'Edit' }} User</h2>
  <button mat-icon-button mat-dialog-close>
    <mat-icon class="text-white">close</mat-icon>
  </button>
</div>
<mat-dialog-content>
  <div class="flex gap-2">
    <mat-form-field class="flex-1" appearance="fill">
      <mat-label>User Name</mat-label>
      <div class="flex">
        <input matInput placeholder="User Name" [(ngModel)]="user.name" required>
      </div>
    </mat-form-field>
    <mat-form-field>
      <mat-label>User Phone</mat-label>
      <input matInput placeholder="User Phone" [(ngModel)]="user.phone" [pattern]="phonePattern" required>
      <mat-icon matSuffix>phone</mat-icon>
    </mat-form-field>
  </div>

  <div class="flex">
    <mat-form-field class="flex-1" appearance="fill">
      <mat-label>User Notes</mat-label>
      <div class="flex">
        <textarea matInput placeholder="User Notes" [(ngModel)]="user.notes"></textarea>
      </div>
    </mat-form-field>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button [disabled]="loading" (click)="dialogRef.close()">Cancel</button>
  <button mat-button color="primary" [disabled]="loading" (click)="onSubmit()">
    <span class="font-semibold" *ngIf="!loading">{{ isNew ? 'Add' : 'Update' }}</span>
    <span class="flex gap-x-1" *ngIf="loading">{{ isNew ? 'Adding' : 'Saving' }}...
      <mat-spinner *ngIf="loading"
                   diameter="20"
                   color="accent"></mat-spinner>
    </span>
  </button>
</mat-dialog-actions>
