import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { Observable } from 'rxjs';
import { Account } from '@models';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  private api = () => {
    return this.appConfigService.apiBaseUrl + `/accounts`;
  };

  constructor(
    private http: HttpClient,
    private appConfigService: ConfigService
  ) {}

  getAccounts(): Observable<Account[]> {
    return this.http.get<Account[]>(this.api());
  }

  addAccount(account: Account): Observable<Account> {
    return this.http.post<Account>(this.api(), account);
  }

  deleteAccount(id: number): Observable<Account> {
    return this.http.delete<Account>(this.api() + `/${id}`);
  }
}
