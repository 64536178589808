import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { Group, Project, User } from '@models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GroupService {

  private api = () => {
    return this.appConfigService.apiBaseUrl + `/groups`;
  };

  constructor(
    private http: HttpClient,
    private appConfigService: ConfigService
  ) { }

  getGroups(): Observable<Group[]>{
    return this.http.get<Group[]>(this.api());
  }

  getGroup(id: number): Observable<Group>{
    return this.http.get<Group>(this.api() + `/${id}`);
  }

  addGroup(group: Group): Observable<Group>{
    return this.http.post<Group>(this.api(), group);
  }

  updateGroup(group: Group): Observable<Group>{
    return this.http.post<Group>(this.api() + `/${group.id}`, group);
  }

  deleteGroup(id: number): Observable<Group>{
    return this.http.delete<Group>(this.api() + `/${id}`);
  }

  getGroupProjects(id: number): Observable<Project[]>{
    return this.http.get<Project[]>(this.api() + `/${id}/projects`);
  }

  addGroupProjects(id: number, projects: Project[]): Observable<Project[]>{
    return this.http.post<Project[]>(this.api() + `/${id}/projects`, projects);
  }

  deleteGroupProjects(id: number, projects: Project[]): Observable<Project[]>{
    return this.http.delete<Project[]>(this.api() + `/${id}/projects`, {body: projects});
  }
  getGroupUsers(id: number): Observable<User[]>{
    return this.http.get<User[]>(this.api() + `/${id}/users`);
  }

  addGroupUsers(id: number, users: User[]): Observable<User[]>{
    return this.http.post<User[]>(this.api() + `/${id}/users`, users);
  }

  deleteGroupUsers(id: number, users: User[]): Observable<User[]>{
    return this.http.delete<User[]>(this.api() + `/${id}/users`, {body: users});
  }
}
