import { createAction, props } from '@ngrx/store';
import { Group, Project, User } from '@models';

export const loadGroups = createAction('[Group] Load Groups');
export const loadGroupsSuccess = createAction('[Group] Load Groups Success', props<{ data: Group[] }>());
export const loadGroupsFailure = createAction('[Group] Load Groups Failure', props<{ error: any }>());
export const loadGroup = createAction('[Group] Load Group', props<{ id: number }>());
export const loadGroupSuccess = createAction('[Group] Load Group Success', props<{ group: Group }>());
export const loadGroupFailure = createAction('[Group] Load Group Failure', props<{ error: any }>());
export const addGroup = createAction('[Group] Add Group', props<{ group: Group }>());
export const addGroupSuccess = createAction('[Group] Add Group Success', props<{ group: Group }>());
export const addGroupFailure = createAction('[Group] Add Group Failure', props<{ error: any }>());
export const updateGroup = createAction('[Group] Update Group', props<{ group: Group }>());
export const updateGroupSuccess = createAction('[Group] Update Group Success', props<{ group: Group }>());
export const updateGroupFailure = createAction('[Group] Update Group Failure', props<{ error: any }>());
export const deleteGroup = createAction('[Group] Delete Group', props<{ id: number }>());
export const deleteGroupSuccess = createAction('[Group] Delete Group Success', props<{ id: number }>());
export const deleteGroupFailure = createAction('[Group] Delete Group Failure', props<{ error: any }>());
export const loadGroupProjects = createAction('[Group] Load Group Projects', props<{ groupId: number }>());
export const loadGroupProjectsSuccess = createAction('[Group] Load Group Projects Success', props<{
  groupId: number,
  projects: Project[]
}>());
export const loadGroupProjectsFailure = createAction('[Group] Load Group Projects Failure', props<{ error: any }>());
export const addGroupProjects = createAction('[Group] Add Group Project', props<{
  groupId: number,
  projects: Project[]
}>());
export const addGroupProjectsSuccess = createAction('[Group] Add Group Project Success', props<{ data: Project[] }>());
export const addGroupProjectsFailure = createAction('[Group] Add Group Project Failure', props<{ error: any }>());
export const deleteGroupProjects = createAction('[Group] Delete Group Project', props<{
  groupId: number,
  projects: Project[]
}>());
export const deleteGroupProjectsSuccess = createAction('[Group] Delete Group Project Success', props<{
  data: Project[]
}>());
export const deleteGroupProjectsFailure = createAction('[Group] Delete Group Project Failure', props<{
  error: any
}>());
export const loadGroupUsers = createAction('[Group] Load Group Users', props<{ groupId: number }>());
export const loadGroupUsersSuccess = createAction('[Group] Load Group Users Success', props<{
  groupId: number,
  users: User[]
}>());
export const loadGroupUsersFailure = createAction('[Group] Load Group Users Failure', props<{ error: any }>());
export const addGroupUsers = createAction('[Group] Add Group User', props<{ groupId: number, users: User[] }>());
export const addGroupUsersSuccess = createAction('[Group] Add Group User Success', props<{ data: User[] }>());
export const addGroupUsersFailure = createAction('[Group] Add Group User Failure', props<{ error: any }>());
export const deleteGroupUsers = createAction('[Group] Delete Group User', props<{ groupId: number, users: User[] }>());
export const deleteGroupUsersSuccess = createAction('[Group] Delete Group User Success', props<{ data: User[] }>());
export const deleteGroupUsersFailure = createAction('[Group] Delete Group User Failure', props<{ error: any }>());
