<div class="alert-dialog">
  <div class="flex justify-between items-baseline">
    <h2 mat-dialog-title>{{ title }}</h2>
    <button mat-icon-button mat-dialog-close>
      <mat-icon class="text-white">close</mat-icon>
    </button>
  </div>
  <mat-dialog-content>
    <div class="alert-dialog-content" matDialogContent>
      <div class="alert-dialog-icon" [ngClass]="type.toLowerCase()">
        <mat-icon class="blue" *ngIf="type === ConfirmDialogType.CONFIRM">help</mat-icon>
        <mat-icon class="orange" *ngIf="type === ConfirmDialogType.WARN">warning</mat-icon>
        <mat-icon class="red" *ngIf="type === ConfirmDialogType.DELETE">delete</mat-icon>
      </div>
      <div class="alert-dialog-message" [innerHtml]="message"></div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button mat-dialog-close>{{ cancelText }}</button>
    <button mat-button color="primary" (click)="onConfirm()">
      <span class="font-semibold" [ngClass]="{red: type === ConfirmDialogType.DELETE,
      orange: type === ConfirmDialogType.WARN}">{{ okText }}</span>
    </button>
  </mat-dialog-actions>
</div>
