@if (!isLoginRoute()) {
  <mat-toolbar>
    <div class="app-icon">
      <a class="app-icon-link" routerLink="/projects">
        <i class="fa fa-door-open"></i>
      </a>
    </div>
    <div class="main-menu">
      <a class="menu-item" mat-button routerLink="/projects" routerLinkActive="active">Projects</a>
      <a class="menu-item" mat-button routerLink="/users" routerLinkActive="active">Users</a>
      <a class="menu-item" mat-button routerLink="/groups" routerLinkActive="active">Groups</a>
      <a class="menu-item" mat-button routerLink="/cards" routerLinkActive="active">Cards</a>
      <a class="menu-item" mat-button routerLink="/controllers" routerLinkActive="active">Controllers</a>
      <a class="menu-item" mat-button routerLink="/activities" routerLinkActive="active">Activities</a>
      <a class="menu-item" mat-button routerLink="/accounts" routerLinkActive="active">Accounts</a>
    </div>
    <div class="right-menu">
      <button class="menu-item" mat-icon-button title="Sign out" (click)="logout()">
        <fa-icon [icon]="faSignOutAlt"></fa-icon>
      </button>
    </div>
  </mat-toolbar>
}
<router-outlet></router-outlet>
