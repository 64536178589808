import { Component, Inject } from '@angular/core';
import { Project } from '@models';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/store';
import * as ProjectActions from '../../store/actions/project.actions';

@Component({
  selector: 'add-project-dialog',
  templateUrl: './add-project-dialog.component.html',
  styleUrl: './add-project-dialog.component.scss',
})
export class AddProjectDialogComponent {
  loading: boolean = false;
  project: Project;
  isNew: boolean;

  constructor(
    private store: Store<AppState>,
    public dialogRef: MatDialogRef<AddProjectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public projectData: Project,
  ) {
    this.isNew = !projectData?.id;
    this.project = projectData || {};
  }

  onSubmit(): void {
    if (!this.project.name) {
      return;
    }

    this.project.id ? this.store.dispatch(ProjectActions.updateProject({ project: this.project })) : this.store.dispatch(ProjectActions.addProject({ project: this.project }));

    this.dialogRef.close(this.project);

  }

  onCancelClick(): void {
    this.dialogRef.close();
  }
}
