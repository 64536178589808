import { Component, OnInit, ViewChild } from '@angular/core';
import { faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { map, Observable, tap } from 'rxjs';
import { Card } from '@models';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../store/store';
import { SharedService } from '@services';
import { MatDialog } from '@angular/material/dialog';
import * as CardSelectors from '../../store/selectors/card.selectors';
import * as CardActions from '../../store/actions/card.actions';
import { AddCardDialogComponent, ConfirmDialogComponent, ConfirmDialogType } from '@components';

@Component({
  selector: 'cards',
  templateUrl: './cards.component.html',
  styleUrl: './cards.component.scss',
})
export class CardsComponent implements OnInit {
  cards$?: Observable<Card[]>;
  isLoading$?: Observable<boolean>;
  cards: Card[] = [];
  displayedColumns: string[] = ['data', 'type', 'notes', 'timestampCreated', 'actions'];
  searchValue?: string;
  dataSource = new MatTableDataSource<Card>();

  constructor(
    private store: Store<AppState>,
    private sharedService: SharedService,
    public dialog: MatDialog,
  ) {
  }

  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
    this.dataSource.sortingDataAccessor = this.sharedService.sortingDataAccessorForNestedProperties;
  };

  ngOnInit(): void {
    this.initSubscriptions();
  }

  initSubscriptions(): void {
    this.cards$ = this.store.pipe(
      select(CardSelectors.selectCards),
      tap(cards => {
        if (!cards || cards.length === 0) {
          this.store.dispatch(CardActions.loadCards());
        }
      }),
      map(cards => {
        this.cards = cards.map(c => ({ ...c, notesCharLimit: 100 }));
        this.dataSource.data = this.cards;
        return cards;
      }),
    );
    this.isLoading$ = this.store.pipe(select(CardSelectors.selectCardIsLoading));
  }

  filterCards(val: string | undefined): void {
    this.searchValue = val;
    if (!val) {
      this.dataSource.data = this.cards;
      return;
    }
    this.dataSource.data = this.cards.filter(c => ['data', 'type', 'notes', 'timestampCreated']
      .some(k => c[k as keyof Card]?.toString().toLowerCase().includes(val.toLowerCase())));
  }

  showManageCardDialog(card?: Card): void {
    this.dialog.open(AddCardDialogComponent, {
      width: '500px',
      data: { ...card },
    });
  }

  showDeleteDialog(card: Card): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        type: ConfirmDialogType.DELETE,
        title: 'Delete Card',
        message: `Are you sure you want to delete card \'${card.data}\'?`,
        okText: 'Delete'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.store.dispatch(CardActions.deleteCard({ id: card.id! }));
      }
    });
  }


  protected readonly faPlusCircle = faPlusCircle;
  protected readonly faTrash = faTrash;
}
