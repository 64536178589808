import { Controller } from './controller.model';

export type Door = {
  id?: number;
  name?: string;
  notes?: string;
  notesCharLimit?: number;
  timestampCreated?: string;
  controller?: Controller;
  controllerId?: number;
  position?: number;
}
