import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { DoorService, SharedService } from '@services';
import * as DoorActions from '../actions/door.actions';
import { catchError, map, mergeMap, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DoorEffects {
  constructor(
    private actions$: Actions,
    private doorService: DoorService,
    private sharedService: SharedService
  ) {}

  loadDoors$ = createEffect(() => this.actions$.pipe(
    ofType(DoorActions.loadDoors),
    mergeMap(() => this.doorService.getDoors().pipe(
      map(data => DoorActions.loadDoorsSuccess({ data })),
      catchError(error => of(DoorActions.loadDoorsFailure({ error })))
    ))
  ));

  loadDoor$ = createEffect(() => this.actions$.pipe(
    ofType(DoorActions.loadDoor),
    map(action => action.id),
    mergeMap(id => this.doorService.getDoor(id).pipe(
      map(door => DoorActions.loadDoorSuccess({ door })),
      catchError(error => of(DoorActions.loadDoorFailure({ error })))
    ))
  ));

  addDoor$ = createEffect(() => this.actions$.pipe(
    ofType(DoorActions.addDoor),
    mergeMap(action => this.doorService.addDoor(action.door).pipe(
      map(door => {
        this.sharedService.showSuccessSnackbar('Door added successfully');
        return DoorActions.addDoorSuccess({ door });
      }),
      catchError(error => of(DoorActions.addDoorFailure({ error })))
    ))
  ));

  updateDoor$ = createEffect(() => this.actions$.pipe(
    ofType(DoorActions.updateDoor),
    mergeMap(action => this.doorService.updateDoor(action.door).pipe(
      map(door => {
        this.sharedService.showSuccessSnackbar('Door updated successfully');
        return DoorActions.updateDoorSuccess({ door });
      }),
      catchError(error => of(DoorActions.updateDoorFailure({ error })))
    ))
  ));

  deleteDoor$ = createEffect(() => this.actions$.pipe(
    ofType(DoorActions.deleteDoor),
    mergeMap(action => this.doorService.deleteDoor(action.id).pipe(
      map(door => {
        this.sharedService.showSuccessSnackbar('Door deleted successfully');
        return DoorActions.deleteDoorSuccess({ id: action.id });
      }),
      catchError(error => of(DoorActions.deleteDoorFailure({ error })))
    ))
  ));

}
