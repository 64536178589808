import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'add-options-dialog',
  templateUrl: './add-options-dialog.component.html',
  styleUrl: './add-options-dialog.component.scss',
})
export class AddOptionsDialogComponent<T> {

  selected: T[] = [];

  constructor(
    public dialogRef: MatDialogRef<AddOptionsDialogComponent<T>>,
    @Inject(MAT_DIALOG_DATA) public data: {multiple?: boolean, options: T[], displayProperty?: keyof T, title: string },
  ) {
  }

  getDisplayValue(option: T): string {
    if (this.data.displayProperty) {
      return option[this.data.displayProperty] as string;
    }
    return option as string;
  }

  onConfirm(): void {
    if (!this.selected?.length) {
      return;
    }
    this.dialogRef.close(this.selected);
  }
}
