import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProjectState } from '../reducers/project.reducers';

export const selectProjectState = createFeatureSelector<ProjectState>('project');
export const selectProjects = createSelector(selectProjectState, (state: ProjectState) => state.data);
export const selectSelectedProject = createSelector(selectProjectState, (state: ProjectState) => state.selectedProject);
export const selectProjectIsLoading = createSelector(selectProjectState, (state: ProjectState) => state.loading);
export const selectSelectedProjectGroups = createSelector(selectProjectState, (state: ProjectState) => state.selectedProject?.groups);
export const selectSelectedProjectControllers = createSelector(selectProjectState, (state: ProjectState) => state.selectedProject?.controllers);
// export const selectProjectById = (id: number) => createSelector(selectProjects, projects => projects.find((p: Project) => p.id === id));
