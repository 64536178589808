import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { map, Observable, Subject, takeUntil, tap } from 'rxjs';
import { Controller, Door } from '@models';
import { MatTableDataSource } from '@angular/material/table';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../store/store';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import * as ControllerActions from '../../../../store/actions/controller.actions';
import * as ControllerSelectors from '../../../../store/selectors/controller.selectors';
import * as DoorSelectors from '../../../../store/selectors/door.selectors';
import * as DoorActions from '../../../../store/actions/door.actions';
import {
  AddDoorDialogComponent,
  ConfirmDialogComponent,
  ConfirmDialogType,
} from '@components';
import { faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'controller-doors',
  templateUrl: './controller-doors.component.html',
  styleUrl: './controller-doors.component.scss',
})
export class ControllerDoorsComponent implements OnInit, OnDestroy {
  controllerDoors$?: Observable<Door[] | undefined>;
  isLoading$?: Observable<boolean>;
  controllerDoors: Door[] = [];
  doors: Door[] = [];
  controllerId?: number;
  controller: Controller = {} as Controller;
  displayedColumns: string[] = ['position', 'name', 'notes', 'actions'];
  dataSource = new MatTableDataSource<(Door | undefined)>();
  private unsubscribe$ = new Subject<void>();

  constructor(
    private store: Store<AppState>,
    public dialog: MatDialog,
    public route: ActivatedRoute,
  ) {
    this.store.pipe(select(ControllerSelectors.selectSelectedController)).subscribe(controller => this.controller = controller!);
  }

  ngOnInit() {
    this.initSubscriptions();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  initSubscriptions(): void {
    this.route.parent?.paramMap.pipe(
      map(params => params.get('id')),
      tap(id => {
        if (id) {
          this.controllerId = +id;
          this.controllerDoors$ = this.store.pipe(
            select(ControllerSelectors.selectSelectedControllerDoors),
            tap(controllerDoors => {
              if (!controllerDoors) {
                this.store.dispatch(ControllerActions.loadControllerDoors({ controllerId: +id }));
              }
            }),
            map(controllerDoors => {
              this.controllerDoors = (controllerDoors || []).map(cd => ({ ...cd, notesCharLimit: 100 }));
              this.dataSource.data = Array.from({ length: 4 }).map((item, index) => {
                return this.controllerDoors?.find(cd => cd.position == index + 1);
              });
              return controllerDoors;
            }),
          );
        }
      }),
      takeUntil(this.unsubscribe$),
    ).subscribe();

    this.store.pipe(
      select(DoorSelectors.selectDoors),
      tap(doors => {
        if (!doors || doors.length === 0) {
          this.store.dispatch(DoorActions.loadDoors());
        }
      }),
      takeUntil(this.unsubscribe$),
    ).subscribe(doors => {
      this.doors = doors;
    });

    this.isLoading$ = this.store.pipe(
      select(ControllerSelectors.selectControllerIsLoading),
      takeUntil(this.unsubscribe$),
    );
  }

  showAddControllerDoorDialog(position: number): void {
    const dialogRef = this.dialog.open(AddDoorDialogComponent, {
      width: '500px',
      data: { controllerId: this.controllerId, position: position },
    });
  }

  showRemoveDialog(door: Door): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        type: ConfirmDialogType.DELETE,
        title: 'Remove Door',
        message: `Are you sure you want to remove door \'${door.name}\' from \'${this.controller.name}\'?`,
        okText: 'Remove',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.store.dispatch(ControllerActions.deleteControllerDoor({
          controllerId: this.controller.id!,
          doorId: door.id!,
        }));
      }
    });
  }

  protected readonly faPlusCircle = faPlusCircle;
  protected readonly faTrash = faTrash;
}
