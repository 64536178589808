import { Component, OnInit, ViewChild } from '@angular/core';
import { faEdit, faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { User } from '@models';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { SharedService } from '@services';
import { MatDialog } from '@angular/material/dialog';
import {
  AddUserDialogComponent,
  ConfirmDialogComponent,
  ConfirmDialogType
} from '@components';
import { map, Observable, tap } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../store/store';
import * as UserSelectors from '../../store/selectors/user.selectors';
import * as UserActions from '../../store/actions/user.actions';

@Component({
  selector: 'users',
  templateUrl: './users.component.html',
  styleUrl: './users.component.scss',
})
export class UsersComponent implements OnInit {
  users$?: Observable<User[]>;
  isLoading$?: Observable<boolean>;
  users: User[] = [];
  displayedColumns: string[] = ['name', 'phone', 'createdByAccount.username', 'notes', 'timestampCreated', 'actions'];
  searchValue?: string;
  dataSource = new MatTableDataSource<User>();

  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
    this.dataSource.sortingDataAccessor = this.sharedService.sortingDataAccessorForNestedProperties;
  };

  constructor(
    private store: Store<AppState>,
    private sharedService: SharedService,
    public dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    this.initSubscriptions();
  }

  initSubscriptions(): void {
    this.users$ = this.store.pipe(
      select(UserSelectors.selectUsers),
      tap(users => {
        if (!users || users.length === 0) {
          this.store.dispatch(UserActions.loadUsers());
        }
      }),
      map(users => {
        this.users = users.map(p => ({ ...p, notesCharLimit: 100 }));
        this.dataSource.data = this.users;
        return users;
      }),
    );
    this.isLoading$ = this.store.pipe(select(UserSelectors.selectUserIsLoading));
  }

  filterUsers(val: string | undefined): void {
    this.searchValue = val;
    if (!val) {
      this.dataSource.data = this.users;
      return;
    }
    this.dataSource.data = this.users.filter(u => ['name', 'phone', 'notes', 'timestampCreated']
      .some(k => u[k as keyof User]?.toString().toLowerCase().includes(val.toLowerCase())
        || u.createdByAccount?.username?.toLowerCase().includes(val.toLowerCase())));
  }

  showManageUserDialog(user?: User): void {
    this.dialog.open(AddUserDialogComponent, {
      width: '500px',
      data: { ...user },
    });
  }

  showDeleteDialog(user: User): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        type: ConfirmDialogType.DELETE,
        title: 'Delete User',
        message: `Are you sure you want to delete user \'${user.name}\'?`,
        okText: 'Delete',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.store.dispatch(UserActions.deleteUser({ id: user.id! }));
      }
    });
  }


  protected readonly faEdit = faEdit;
  protected readonly faTrash = faTrash;
  protected readonly faPlusCircle = faPlusCircle;
}
