<mat-form-field class="w-full" appearance="fill">
  <mat-label class="flex justify-between content-center">
    <mat-icon class="flex content-center mr-2 search-icon">search</mat-icon>
    Search
  </mat-label>
  <input matInput type="text" (keyup)="emitSearchValue($event)" placeholder="Search..." #input>
  <button class="clear-button" mat-icon-button *ngIf="input.value" (click)="clearInput()">
    <mat-icon mat-icon-button matSuffix>close</mat-icon>
  </button>
</mat-form-field>
