import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CardService, SharedService } from '@services';
import * as CardActions from '../actions/card.actions';
import { catchError, map, mergeMap, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CardEffects {
  constructor(
    private actions$: Actions,
    private cardService: CardService,
    private sharedService: SharedService
  ) {}

  loadCards$ = createEffect(() => this.actions$.pipe(
    ofType(CardActions.loadCards),
    mergeMap(() => this.cardService.getCards().pipe(
      map(data => CardActions.loadCardsSuccess({ data })),
      catchError(error => of(CardActions.loadCardsFailure({ error })))
    ))
  ));

  loadCard$ = createEffect(() => this.actions$.pipe(
    ofType(CardActions.loadCard),
    map(action => action.id),
    mergeMap(id => this.cardService.getCard(id).pipe(
      map(card => CardActions.loadCardSuccess({ card })),
      catchError(error => of(CardActions.loadCardFailure({ error })))
    ))
  ));

  addCard$ = createEffect(() => this.actions$.pipe(
    ofType(CardActions.addCard),
    mergeMap(action => this.cardService.addCard(action.card).pipe(
      map(card => {
        this.sharedService.showSuccessSnackbar('Card added successfully');
        return CardActions.addCardSuccess({ card });
      }),
      catchError(error => of(CardActions.addCardFailure({ error })))
    ))
  ));

  updateCard$ = createEffect(() => this.actions$.pipe(
    ofType(CardActions.updateCard),
    mergeMap(action => this.cardService.updateCard(action.card).pipe(
      map(card => {
        this.sharedService.showSuccessSnackbar('Card updated successfully');
        return CardActions.updateCardSuccess({ card });
      }),
      catchError(error => of(CardActions.updateCardFailure({ error })))
    ))
  ));

  deleteCard$ = createEffect(() => this.actions$.pipe(
    ofType(CardActions.deleteCard),
    mergeMap(action => this.cardService.deleteCard(action.id).pipe(
      map(card => {
        this.sharedService.showSuccessSnackbar('Card deleted successfully');
        return CardActions.deleteCardSuccess({ id: action.id });
      }),
      catchError(error => of(CardActions.deleteCardFailure({ error })))
    ))
  ));

}
