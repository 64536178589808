import {Component, Inject, Input} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

export enum ConfirmDialogType {
  CONFIRM = 'confirm',
  WARN = 'warn',
  DELETE = 'delete'
}

export interface ConfirmConfig {
  type?: ConfirmDialogType;
  title?: string;
  message?: string;
  okText?: string;
  cancelText?: string;
}

@Component({
  selector: 'confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {
  protected readonly ConfirmDialogType = ConfirmDialogType;
  @Input() public type: ConfirmDialogType = ConfirmDialogType.CONFIRM;
  @Input() public title: string = 'Confirm';
  @Input() public message: string = 'Are you sure?';
  @Input() public okText: string = 'Confirm';
  @Input() public confirmingText: string = 'Saving...';
  @Input() public cancelText: string = 'Cancel';

  constructor(
    private dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) dialogData: ConfirmConfig
  ) {
    if (dialogData) {
      this.type = dialogData.type || this.type;
      this.title = dialogData.title || this.title;
      this.message = dialogData.message || this.message;
      this.okText = dialogData.okText || this.okText;
      this.cancelText = dialogData.cancelText || this.cancelText;
    }
  }

  onConfirm() {
    this.dialogRef.close(true);
  }
}
