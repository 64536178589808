import { Controller } from '@models';
import { createReducer, on } from '@ngrx/store';
import * as ControllerActions from '../actions/controller.actions';

export interface ControllerState {
  data: Controller[];
  selectedController: Controller | null;
  loading: boolean;
  error: string;
}

export const initialControllerState: ControllerState = {
  data: [],
  selectedController: null,
  loading: false,
  error: '',
};

const reducer = createReducer<ControllerState>(
  initialControllerState,
  on(ControllerActions.loadControllers, (state) => ({ ...state, loading: true })),
  on(ControllerActions.loadControllersSuccess, (state, { data }) => ({ ...state, data: data, loading: false })),
  on(ControllerActions.loadControllersFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(ControllerActions.loadController, (state) => ({ ...state, loading: true })),
  on(ControllerActions.loadControllerSuccess, (state, { controller }) => ({
    ...state,
    selectedController: controller,
    loading: false,
  })),
  on(ControllerActions.loadControllerFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(ControllerActions.addController, (state, { controller }) => ({ ...state, loading: true })),
  on(ControllerActions.addControllerSuccess, (state, { controller }) => ({
    ...state,
    data: [...state.data, controller],
    loading: false,
  })),
  on(ControllerActions.addControllerFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(ControllerActions.updateController, (state, { controller }) => ({
    ...state,
    loading: true,
  })),
  on(ControllerActions.updateControllerSuccess, (state, { controller }) => ({
    ...state,
    data: state.data.map(u => u.id === controller.id ? controller : u),
    loading: false,
  })),
  on(ControllerActions.updateControllerFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(ControllerActions.deleteController, (state, { id }) => ({ ...state, loading: true })),
  on(ControllerActions.deleteControllerSuccess, (state, { id }) => ({
    ...state,
    data: state.data.filter(u => u.id !== id),
    loading: false,
  })),
  on(ControllerActions.deleteControllerFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(ControllerActions.loadControllerDoors, (state) => ({ ...state, loading: true })),
  on(ControllerActions.loadControllerDoorsSuccess, (state, { controllerId, doors }) => ({
    ...state,
    selectedController: { ...state.selectedController, doors: doors },
    loading: false,
  })),
  on(ControllerActions.loadControllerDoorsFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(ControllerActions.addControllerDoor, (state) => ({ ...state, loading: true })),
  on(ControllerActions.addControllerDoorSuccess, (state, { data }) => ({
    ...state,
    selectedController: { ...state.selectedController, doors: data },
    loading: false,
  })),
  on(ControllerActions.addControllerDoorFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(ControllerActions.deleteControllerDoor, (state) => ({ ...state, loading: true })),
  on(ControllerActions.deleteControllerDoorSuccess, (state, { doorId }) => ({
    ...state,
    selectedController: {
      ...state.selectedController,
      doors: state.selectedController?.doors?.filter(d => d.id !== doorId),
    },
    loading: false,
  })),
  on(ControllerActions.deleteControllerDoorFailure, (state, { error }) => ({ ...state, loading: false, error })),
);

export function controllerReducer(state = initialControllerState, action: any): ControllerState {
  return reducer(state, action);
}
