import { createAction, props } from '@ngrx/store';
import { Controller, Group, Project } from '@models';

export const loadProjects = createAction('[Project] Load Projects');
export const loadProjectsSuccess = createAction('[Project] Load Projects Success', props<{ data: Project[] }>());
export const loadProjectsFailure = createAction('[Project] Load Projects Failure', props<{ error: any }>());
export const loadProject = createAction('[Project] Load Project', props<{ id: number }>());
export const loadProjectSuccess = createAction('[Project] Load Project Success', props<{ project: Project }>());
export const loadProjectFailure = createAction('[Project] Load Project Failure', props<{ error: any }>());
export const addProject = createAction('[Project] Add Project', props<{ project: Partial<Project> }>());
export const addProjectSuccess = createAction('[Project] Add Project Success', props<{ project: Project }>());
export const addProjectFailure = createAction('[Project] Add Project Failure', props<{ error: any }>());
export const updateProject = createAction('[Project] Update Project', props<{ project: Project }>());
export const updateProjectSuccess = createAction('[Project] Update Project Success', props<{ project: Project }>());
export const updateProjectFailure = createAction('[Project] Update Project Failure', props<{ error: any }>());
export const deleteProject = createAction('[Project] Delete Project', props<{ id: number }>());
export const deleteProjectSuccess = createAction('[Project] Delete Project Success', props<{ id: number }>());
export const deleteProjectFailure = createAction('[Project] Delete Project Failure', props<{ error: any }>());
export const loadProjectGroups = createAction('[Project] Load Project Groups', props<{ projectId: number }>());
export const loadProjectGroupsSuccess = createAction('[Project] Load Project Groups Success', props<{
  projectId: number,
  groups: Group[]
}>());
export const loadProjectGroupsFailure = createAction('[Project] Load Project Groups Failure', props<{
  error: any
}>());
export const addProjectGroups = createAction('[Project] Add Project Group', props<{
  projectId: number,
  groups: Group[]
}>());
export const addProjectGroupsSuccess = createAction('[Project] Add Project Group Success', props<{ data: Group[] }>());
export const addProjectGroupsFailure = createAction('[Project] Add Project Group Failure', props<{ error: any }>());
export const deleteProjectGroups = createAction('[Project] Delete Project Group', props<{
  projectId: number,
  groups: Group[]
}>());
export const deleteProjectGroupsSuccess = createAction('[Project] Delete Project Group Success', props<{
  data: Group[]
}>());
export const deleteProjectGroupsFailure = createAction('[Project] Delete Project Group Failure', props<{
  error: any
}>());
export const loadProjectControllers = createAction('[Project] Load Project Controllers', props<{
  projectId: number
}>());
export const loadProjectControllersSuccess = createAction('[Project] Load Project Controllers Success', props<{
  projectId: number,
  controllers: Controller[]
}>());
export const loadProjectControllersFailure = createAction('[Project] Load Project Controllers Failure', props<{
  error: any
}>());
export const addProjectControllers = createAction('[Project] Add Project Controller', props<{
  projectId: number,
  controllers: Controller[]
}>());
export const addProjectControllersSuccess = createAction('[Project] Add Project Controller Success', props<{
  data: Controller[]
}>());
export const addProjectControllersFailure = createAction('[Project] Add Project Controller Failure', props<{
  error: any
}>());
export const deleteProjectControllers = createAction('[Project] Delete Project Controller', props<{
  projectId: number,
  controllers: Controller[]
}>());
export const deleteProjectControllersSuccess = createAction('[Project] Delete Project Controller Success', props<{
  data: Controller[]
}>());
export const deleteProjectControllersFailure = createAction('[Project] Delete Project Controller Failure', props<{
  error: any
}>());

