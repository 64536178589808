import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { Observable } from 'rxjs';
import { Activity } from '@models';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root',
})
export class ActivityService {

  constructor(
    private http: HttpClient,
    private appConfigService: ConfigService,
    private utilService: UtilService,
  ) {
  }

  private api = () => {
    return this.appConfigService.apiBaseUrl + `/events`;
  };

  getActivities(filters: any): Observable<{ count: number, data: Activity[] }> {
    const httpParams = this.utilService.paramsSerializer(filters);
    return this.http.get<{ count: number, data: Activity[] }>(this.api(), { params: httpParams });
  }
}
