
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserState } from '../reducers/user.reducers';

export const selectUserState = createFeatureSelector<UserState>('user');
export const selectUsers = createSelector(selectUserState, (state: UserState) => state.data);
export const selectSelectedUser = createSelector(selectUserState, (state: UserState) => state.selectedUser);
export const selectSelectedUserGroups = createSelector(selectUserState, (state: UserState) => state.selectedUser?.groups);
export const selectSelectedUserCards = createSelector(selectUserState, (state: UserState) => state.selectedUser?.cards);
export const selectUserIsLoading = createSelector(selectUserState, (state: UserState) => state.loading);
