import { createAction, props } from '@ngrx/store';
import { Controller, Door } from '@models';

export const loadControllers = createAction('[Controller] Load Controllers');
export const loadControllersSuccess = createAction('[Controller] Load Controllers Success', props<{
  data: Controller[]
}>());
export const loadControllersFailure = createAction('[Controller] Load Controllers Failure', props<{ error: any }>());
export const loadController = createAction('[Controller] Load Controller', props<{ id: number }>());
export const loadControllerSuccess = createAction('[Controller] Load Controller Success', props<{
  controller: Controller
}>());
export const loadControllerFailure = createAction('[Controller] Load Controller Failure', props<{ error: any }>());
export const addController = createAction('[Controller] Add Controller', props<{ controller: Partial<Controller> }>());
export const addControllerSuccess = createAction('[Controller] Add Controller Success', props<{
  controller: Controller
}>());
export const addControllerFailure = createAction('[Controller] Add Controller Failure', props<{ error: any }>());
export const updateController = createAction('[Controller] Update Controller', props<{ controller: Controller }>());
export const updateControllerSuccess = createAction('[Controller] Update Controller Success', props<{
  controller: Controller
}>());
export const updateControllerFailure = createAction('[Controller] Update Controller Failure', props<{ error: any }>());
export const deleteController = createAction('[Controller] Delete Controller', props<{ id: number }>());
export const deleteControllerSuccess = createAction('[Controller] Delete Controller Success', props<{ id: number }>());
export const deleteControllerFailure = createAction('[Controller] Delete Controller Failure', props<{ error: any }>());
export const loadControllerDoors = createAction('[Controller] Load Controller Doors', props<{
  controllerId: number
}>());
export const loadControllerDoorsSuccess = createAction('[Controller] Load Controller Doors Success', props<{
  controllerId: number,
  doors: Door[]
}>());
export const loadControllerDoorsFailure = createAction('[Controller] Load Controller Doors Failure', props<{
  error: any
}>());
export const addControllerDoor = createAction('[Controller] Add Controller Door', props<{
  door: Door
}>());
export const addControllerDoorSuccess = createAction('[Controller] Add Controller Door Success', props<{
  data: Door[]
}>());
export const addControllerDoorFailure = createAction('[Controller] Add Controller Door Failure', props<{
  error: any
}>());
export const deleteControllerDoor = createAction('[Controller] Delete Controller Door', props<{
  controllerId: number,
  doorId: number
}>());
export const deleteControllerDoorSuccess = createAction('[Controller] Delete Controller Door Success', props<{
  doorId: number
}>());
export const deleteControllerDoorFailure = createAction('[Controller] Delete Controller Door Failure', props<{
  error: any
}>());
