<h2 mat-dialog-title>{{ data.title }}</h2>
<mat-dialog-content>
  <mat-selection-list [(ngModel)]="selected" [multiple]="data.multiple !== false">
    <mat-list-option *ngFor="let option of data.options" [value]="option">
      {{ getDisplayValue(option) }}
    </mat-list-option>
  </mat-selection-list>
  <div class="no-results" *ngIf="!data.options.length">
    Empty
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="dialogRef.close()">Cancel</button>
  <button mat-button color="primary" (click)="onConfirm()">Confirm</button>
</mat-dialog-actions>
