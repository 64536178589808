import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor() {

  }

  get apiBaseUrl() : string | undefined {
    return environment.apiUrl;
  }

  get baseUrl() : string {
    return window.location.href;
  }
}
