import { Account } from './account.model';
import { Project } from './project.model';
import { User } from './user.model';

export type Group = {
  id?: number;
  name?: string;
  notes?: string;
  timestampCreated?: string;
  createdByAccount?: Account;
  notesCharLimit?: number;
  projects?: Project[];
  users?: User[];
}
