import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as GroupActions from '../actions/group.actions';
import { catchError, map, mergeMap, of } from 'rxjs';
import { GroupService, SharedService } from '@services';

@Injectable({
  providedIn: 'root'
})
export class GroupEffects {

  loadGroups$ = createEffect(() => this.actions$.pipe(
    ofType(GroupActions.loadGroups),
    mergeMap(() => this.groupService.getGroups().pipe(
      map((data) => GroupActions.loadGroupsSuccess({ data })),
      catchError(error => of(GroupActions.loadGroupsFailure({ error })))
    ))
  ));

  loadGroup$ = createEffect(() => this.actions$.pipe(
    ofType(GroupActions.loadGroup),
    mergeMap(action => this.groupService.getGroup(action.id).pipe(
      map((group) => GroupActions.loadGroupSuccess({ group })),
      catchError(error => of(GroupActions.loadGroupFailure({ error })))
    ))
  ));

  addGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GroupActions.addGroup),
      mergeMap(action =>
        this.groupService.addGroup(action.group).pipe(
          map(response => {
            // Assuming response contains the newly added Group
            this.sharedService.showSuccessSnackbar('Group added successfully');
            return GroupActions.addGroupSuccess({ group: response });
          }),
          catchError(error => of(GroupActions.addGroupFailure({ error })))
        )
      )
    )
  );

  updateGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GroupActions.updateGroup),
      mergeMap(action =>
        this.groupService.updateGroup(action.group).pipe(
          map(response => {
            // Assuming response contains the updated Group
            this.sharedService.showSuccessSnackbar('Group updated successfully');
            return GroupActions.updateGroupSuccess({ group: response });
          }),
          catchError(error => of(GroupActions.updateGroupFailure({ error })))
        )
      )
    )
  );

  deleteGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GroupActions.deleteGroup),
      mergeMap(action =>
        this.groupService.deleteGroup(action.id).pipe(
          map(() => {
            this.sharedService.showSuccessSnackbar('Group deleted successfully');
            return GroupActions.deleteGroupSuccess({ id: action.id });
          }),
          catchError(error => of(GroupActions.deleteGroupFailure({ error })))
        )
      )
    )
  );

  loadGroupProjects$ = createEffect(() => this.actions$.pipe(
    ofType(GroupActions.loadGroupProjects),
    mergeMap(action => this.groupService.getGroupProjects(action.groupId).pipe(
      map((data) => GroupActions.loadGroupProjectsSuccess({ groupId: action.groupId, projects: data })),
      catchError(error => of(GroupActions.loadGroupProjectsFailure({ error })))
    ))
  ));

  addGroupProjects$ = createEffect(() => this.actions$.pipe(
    ofType(GroupActions.addGroupProjects),
    mergeMap(action => this.groupService.addGroupProjects(action.groupId, action.projects).pipe(
      map((data) => {
        this.sharedService.showSuccessSnackbar('Projects added successfully');
        return GroupActions.addGroupProjectsSuccess({ data });
      }),
      catchError(error => of(GroupActions.addGroupProjectsFailure({ error })))
    ))
  ));

  deleteGroupProjects$ = createEffect(() => this.actions$.pipe(
    ofType(GroupActions.deleteGroupProjects),
    mergeMap(action => this.groupService.deleteGroupProjects(action.groupId, action.projects).pipe(
      map(() => {
        this.sharedService.showSuccessSnackbar('Project removed successfully');
        return GroupActions.deleteGroupProjectsSuccess({ data: action.projects });
      }),
      catchError(error => of(GroupActions.deleteGroupProjectsFailure({ error })))
    ))
  ));

  loadGroupUsers$ = createEffect(() => this.actions$.pipe(
    ofType(GroupActions.loadGroupUsers),
    mergeMap(action => this.groupService.getGroupUsers(action.groupId).pipe(
      map((data) => GroupActions.loadGroupUsersSuccess({ groupId: action.groupId, users: data })),
      catchError(error => of(GroupActions.loadGroupUsersFailure({ error })))
    ))
  ));

  addGroupUsers$ = createEffect(() => this.actions$.pipe(
    ofType(GroupActions.addGroupUsers),
    mergeMap(action => this.groupService.addGroupUsers(action.groupId, action.users).pipe(
      map((data) => {
        this.sharedService.showSuccessSnackbar('Users added successfully');
        return GroupActions.addGroupUsersSuccess({ data });
      }),
      catchError(error => of(GroupActions.addGroupUsersFailure({ error })))
    ))
  ));

  deleteGroupUsers$ = createEffect(() => this.actions$.pipe(
    ofType(GroupActions.deleteGroupUsers),
    mergeMap(action => this.groupService.deleteGroupUsers(action.groupId, action.users).pipe(
      map(() => {
        this.sharedService.showSuccessSnackbar('User removed successfully');
        return GroupActions.deleteGroupUsersSuccess({ data: action.users });
      }),
      catchError(error => of(GroupActions.deleteGroupUsersFailure({ error })))
    ))
  ));

  constructor(
    private actions$: Actions,
    private groupService: GroupService,
    private sharedService: SharedService
  ) { }
}
