import { createAction, props } from '@ngrx/store';
import { Card, Group, User } from '@models';

export const loadUsers = createAction('[User] Load Users');
export const loadUsersSuccess = createAction('[User] Load Users Success', props<{ data: User[] }>());
export const loadUsersFailure = createAction('[User] Load Users Failure', props<{ error: any }>());
export const loadUser = createAction('[User] Load User', props<{ id: number }>());
export const loadUserSuccess = createAction('[User] Load User Success', props<{ user: User }>());
export const loadUserFailure = createAction('[User] Load User Failure', props<{ error: any }>());
export const addUser = createAction('[User] Add User', props<{ user: Partial<User> }>());
export const addUserSuccess = createAction('[User] Add User Success', props<{ user: User }>());
export const addUserFailure = createAction('[User] Add User Failure', props<{ error: any }>());
export const updateUser = createAction('[User] Update User', props<{ user: User }>());
export const updateUserSuccess = createAction('[User] Update User Success', props<{ user: User }>());
export const updateUserFailure = createAction('[User] Update User Failure', props<{ error: any }>());
export const deleteUser = createAction('[User] Delete User', props<{ id: number }>());
export const deleteUserSuccess = createAction('[User] Delete User Success', props<{ id: number }>());
export const deleteUserFailure = createAction('[User] Delete User Failure', props<{ error: any }>());
export const loadUserGroups = createAction('[User] Load User Groups', props<{ userId: number }>());
export const loadUserGroupsSuccess = createAction('[User] Load User Groups Success', props<{
  userId: number,
  groups: Group[]
}>());
export const loadUserGroupsFailure = createAction('[User] Load User Groups Failure', props<{
  error: any
}>());
export const addUserGroups = createAction('[User] Add User Group', props<{
  userId: number,
  groups: Group[]
}>());
export const addUserGroupsSuccess = createAction('[User] Add User Group Success', props<{ data: Group[] }>());
export const addUserGroupsFailure = createAction('[User] Add User Group Failure', props<{ error: any }>());
export const deleteUserGroups = createAction('[User] Delete User Group', props<{
  userId: number,
  groups: Group[]
}>());
export const deleteUserGroupsSuccess = createAction('[User] Delete User Group Success', props<{ data: Group[] }>());
export const deleteUserGroupsFailure = createAction('[User] Delete User Group Failure', props<{ error: any }>());
export const loadUserCards = createAction('[User] Load User Cards', props<{ userId: number }>());
export const loadUserCardsSuccess = createAction('[User] Load User Cards Success', props<{
  userId: number,
  cards: Card[]
}>());
export const loadUserCardsFailure = createAction('[User] Load User Cards Failure', props<{
  error: any
}>());
export const addUserCards = createAction('[User] Add User Card', props<{
  userId: number,
  cards: Card[]
}>());
export const addUserCardsSuccess = createAction('[User] Add User Card Success', props<{ data: Card[] }>());
export const addUserCardsFailure = createAction('[User] Add User Card Failure', props<{ error: any }>());
export const deleteUserCards = createAction('[User] Delete User Card', props<{
  userId: number,
  cards: Card[]
}>());
export const deleteUserCardsSuccess = createAction('[User] Delete User Card Success', props<{ data: Card[] }>());
export const deleteUserCardsFailure = createAction('[User] Delete User Card Failure', props<{ error: any }>());
