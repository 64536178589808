import { Component, OnInit } from '@angular/core';
import { map, Observable } from 'rxjs';
import { User } from '@models';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../store/store';
import * as UserSelectors from '../../../store/selectors/user.selectors';
import * as UserActions from '../../../store/actions/user.actions';

@Component({
  selector: 'user',
  templateUrl: './user.component.html',
  styleUrl: './user.component.scss'
})
export class UserComponent implements OnInit{
  user$?: Observable<User | null>;
  isLoading$?: Observable<boolean>;
  user?: User;

  constructor(
    private route: ActivatedRoute,
    private store: Store<AppState>,
  ) {
    this.user$ = this.store.pipe(select(UserSelectors.selectSelectedUser));
  }

  ngOnInit(): void {
    this.initSubscriptions();
  }

  initSubscriptions(): void {
    this.route.paramMap.pipe(
      map(params => params.get('id')),
    ).subscribe(id => {
      if (id) {
        this.store.dispatch(UserActions.loadUser({ id: +id }));
      }
    });
    this.isLoading$ = this.store.pipe(select(UserSelectors.selectUserIsLoading));
  }
}
