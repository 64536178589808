import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'charLimit'
})
export class CharLimitPipe implements PipeTransform {
  transform(value: string, limit?: number): string {
    if (!limit || !value || value.length <= limit) {
      return value;
    }
    return value.substring(0, limit) + '[...]';
  }
}
