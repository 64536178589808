import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ActivityService } from '@services';
import * as ActivityActions from '../actions/activity.actions';
import * as ActivitySelectors from '../selectors/activity.selectors';
import { catchError, map, mergeMap, of, withLatestFrom } from 'rxjs';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class ActivityEffects {

  constructor(
    private actions$: Actions,
    private activityService: ActivityService,
    private store: Store,
  ) {
  }

  loadActivities$ = createEffect(() => this.actions$.pipe(
    ofType(ActivityActions.updateFilters),
    withLatestFrom(this.store.select(ActivitySelectors.selectActivityFilters)),
    mergeMap(data => this.activityService.getActivities(data[1]).pipe(
        map((data) => ActivityActions.loadActivitiesSuccess({ data })),
        catchError(error => of(ActivityActions.loadActivitiesFailure({ error }))),
      ),
    ),
  ));
}
