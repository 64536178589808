import { Component, OnInit, ViewChild } from '@angular/core';
import { faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { map, Observable, tap } from 'rxjs';
import { Door } from '@models';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../store/store';
import { SharedService } from '@services';
import { MatDialog } from '@angular/material/dialog';
import * as DoorSelectors from '../../store/selectors/door.selectors';
import * as DoorActions from '../../store/actions/door.actions';
import { AddDoorDialogComponent, ConfirmDialogComponent, ConfirmDialogType } from '@components';

@Component({
  selector: 'doors',
  templateUrl: './doors.component.html',
  styleUrl: './doors.component.scss',
})
export class DoorsComponent implements OnInit {
  doors$?: Observable<Door[]>;
  isLoading$?: Observable<boolean>;
  doors: Door[] = [];
  displayedColumns: string[] = ['name', 'controller.name', 'notes', 'timestampCreated', 'actions'];
  searchValue?: string;
  dataSource = new MatTableDataSource<Door>();

  constructor(
    private store: Store<AppState>,
    private sharedService: SharedService,
    public dialog: MatDialog,
  ) {
  }

  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
    this.dataSource.sortingDataAccessor = this.sharedService.sortingDataAccessorForNestedProperties;
  };

  ngOnInit(): void {
    this.initSubscriptions();
  }

  initSubscriptions(): void {
    this.doors$ = this.store.pipe(
      select(DoorSelectors.selectDoors),
      tap(doors => {
        if (!doors || doors.length === 0) {
          this.store.dispatch(DoorActions.loadDoors());
        }
      }),
      map(doors => {
        this.doors = doors.map(d => ({ ...d, notesCharLimit: 100 }));
        this.dataSource.data = this.doors;
        return doors;
      }),
    );
    this.isLoading$ = this.store.pipe(select(DoorSelectors.selectDoorIsLoading));
  }

  filterDoors(val: string | undefined): void {
    this.searchValue = val;
    if (!val) {
      this.dataSource.data = this.doors;
      return;
    }
    this.dataSource.data = this.doors.filter(d => ['name', 'notes', 'timestampCreated']
      .some(k => d[k as keyof Door]?.toString().toLowerCase().includes(val.toLowerCase())));
  }

  showManageDoorDialog(door?: Door): void {
    this.dialog.open(AddDoorDialogComponent, {
      width: '500px',
      data: { ...door },
    });
  }

  showDeleteDialog(door: Door): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        type: ConfirmDialogType.DELETE,
        title: 'Delete Door',
        message: `Are you sure you want to delete door \'${door.name}\'?`,
        okText: 'Delete'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.store.dispatch(DoorActions.deleteDoor({ id: door.id! }));
      }
    });
  }


  protected readonly faPlusCircle = faPlusCircle;
  protected readonly faTrash = faTrash;
}
