import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse,
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { AuthService, ConfigService } from '@services';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.headers.has('Authorization') && !!this.authService.getToken()) {
      const token = this.authService.getToken();
      const modifiedRequest = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });

      return next.handle(modifiedRequest).pipe(
        catchError((error: HttpErrorResponse) => this.handleErrorResponse(error)),
      ) as Observable<HttpEvent<any>>;
    }
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => this.handleErrorResponse(error)),
    ) as Observable<HttpEvent<any>>;
  }

  private handleErrorResponse(error: HttpErrorResponse): Observable<HttpEvent<any>> {
    switch (error.status) {
      case -1:
        this.handleConnectionLost();
        break;
      case 400:
      case 404:
      case 409:
        return throwError(error) as Observable<HttpEvent<any>>; // Type assertion to Observable<HttpEvent<any>>
      case 401:
        if (error.error) {
          this.unauthorizedFinally();
        } else {
          return throwError(error) as Observable<HttpEvent<any>>; // Type assertion to Observable<HttpEvent<any>>
        }
        break;
      case 403:
        console.log(error.error);
        return throwError(error) as Observable<HttpEvent<any>>; // Type assertion to Observable<HttpEvent<any>>
      case 500:
        if (typeof error.error === 'string' && error.error.toUpperCase().indexOf('ETIMEDOUT') !== -1) {
          this.handleConnectionLost();
        } else {
          return throwError(error) as Observable<HttpEvent<any>>; // Type assertion to Observable<HttpEvent<any>>
        }
        break;
      default:
        console.error(error);
        // this.modalService.alert(error.error?.message || 'Something went wrong').then(() => {
        //   return throwError(error) as Observable<HttpEvent<any>>; // Type assertion to Observable<HttpEvent<any>>
        // });
        return throwError(error) as Observable<HttpEvent<any>>; // Type assertion to Observable<HttpEvent<any>>
    }

    return throwError(error) as Observable<HttpEvent<any>>; // Type assertion to Observable<HttpEvent<any>>
  }

  private unauthorizedFinally(): void {
    this.authService.clearUser(window.location.href);
  }

  private handleConnectionLost(): void {
    // this.modalService.alert('Could not connect to MEGA servers').then(() => this.unauthorizedFinally());
    console.warn('Could not connect');
  }
}
